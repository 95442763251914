import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext } from "react";
import ApiEndpoints from "../network/ApiEndPoints";
import CachedIcon from "@mui/icons-material/Cached";
import { useState } from "react";
import { CustomStyles } from "../component/CostomStyle";
import ActiveInactiveOperator from "../modals/ActiveInactiveOperator";
import EditOperator from "../modals/EditOperator";
import AddOperatorModal from "../modals/AddOperatorModal";
import ChangeRouteMenu from "../component/ChangeRouteMenu";
import { currencySetter } from "../utils/Currencyutil";
import FilterCard from "../modals/FilterCard";
import AuthContext from "../store/AuthContext";
import FilterModal from "../modals/FilterModal";
import { get } from "../network/ApiController";
import { apiErrorToast } from "../utils/ToastUtil";
import ApiPaginateSearch from "../component/ApiPaginateSearch";

let refresh;
let refreshFilter;
function refreshFunc(setQueryParams) {
  // setQueryParams("");
  if (refresh) refresh();
  // if (refreshFilter) refreshFilter();
}
const AdminOperatorView = () => {
  const [apiData, setApiData] = useState([]);
  const [query, setQuery] = useState();
  const authCtx = useContext(AuthContext);
  const user = authCtx.user;
  const [isShowFilterCard, setIsShowFilterCard] = useState(false);
  const [request, setRequest] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [defaultStatus, setDefaultStatus] = useState("All");

  const handleChangeStatus = (event) => {
    setDefaultStatus(
      event.target.value === "All" ? event.target.value : event.target.value * 1
    );
    if (event.target.value !== "All") {
      setQuery(`active=${event.target.value * 1}`);
    } else if (event.target.value === "All") setQuery("");
  };

  let routeList;
  // const typeList = [
  //   { name: "UTILITY", code: "utility" },
  //   { name: "VERIFICATION", code: "verification" },
  // ];
  const statusList = [
    { name: "ACTIVE", code: 1 },
    { name: "IN-ACTIVE", code: 0 },
  ];
  const searchOptions = [{ field: "Name", parameter: "name" }];

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Code",
      selector: (row) => row.code,
    },
    {
      name: "Type",
      selector: (row) => row.category,
    },
    {
      name: "Route",
      selector: (row) => (
        <div style={{ textAlign: "left" }}>
          <div>{row.route}</div>
          <ChangeRouteMenu row={row} refresh={refresh} />
        </div>
      ),
      center: false,
    },
    {
      name: "Adm Comm",
      selector: (row) => currencySetter(row.admin_comm),
    },
    {
      name: "Ret Comm",
      selector: (row) => currencySetter(row.ret_comm),
    },
    {
      name: "Ad Comm",
      selector: (row) => currencySetter(row.ad_comm),
    },
    {
      name: "Dd Comm",
      selector: (row) => currencySetter(row.dd_comm),
    },
    {
      name: (
        <FormControl className="customized-textfield">
          <TextField
            select
            value={defaultStatus}
            onChange={handleChangeStatus}
            sx={{ color: "#fff" }}
          >
            <MenuItem dense value="All">
              All
            </MenuItem>
            <MenuItem dense value="1">
              ACTIVE
            </MenuItem>
            <MenuItem dense value="0">
              IN-ACTIVE
            </MenuItem>
          </TextField>
        </FormControl>
      ),
      selector: (row) => <ActiveInactiveOperator row={row} refresh={refresh} />,
    },
    {
      name: "Actions",
      selector: (row) => <EditOperator row={row} refresh={refresh} />,
    },
  ];

  function refreshFunc(setQueryParams) {
    setDefaultStatus("Status");
    if (refresh) refresh();
    // if (refreshFilter) refreshFilter();
  }

  // get types
  const getTypes = () => {
    if (typeList.length === 0) {
      get(
        ApiEndpoints.GET_CATEGORIES,
        "",
        setRequest,
        (res) => {
          const data = res.data.data;

          setTypeList(data);
        },
        (err) => {
          apiErrorToast(err);
        }
      );
    }
  };
  return (
    <Grid container xs={12}>
      <Grid lg={12} md={12} sm={12} xs={12}>
        <div className="my-2">
          <ApiPaginateSearch
            actionButtons={
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: { md: "end", xs: "start" },
                  alignItems: "center",
                  pr: 5,
                }}
              >
                <Box
                  sx={{ display: "flex", justifyContent: "end", mr: 5, mt: 2 }}
                >
                  <AddOperatorModal />
                  <Tooltip title="refresh">
                    <IconButton
                      aria-label="refresh"
                      color="success"
                      onClick={() => {
                        refreshFunc(setQuery);
                      }}
                    >
                      <CachedIcon className="refresh-purple" />
                    </IconButton>
                  </Tooltip>
                  <span className="filter-sm">
                    <FilterModal
                      ifTypeFilter
                      ifnameFilter
                      ifrouteFilter
                      ifstatusFilter
                      // getRouteVal={getRouteVal}
                      typeList={typeList}
                      getTypes={getTypes}
                      routeList={routeList}
                      statusList={statusList}
                      operatorList={typeList}
                      setQuery={setQuery}
                      query={query}
                      clearHookCb={(cb) => {
                        refreshFilter = cb;
                      }}
                      refresh={refresh}
                      isShowFilterCard={isShowFilterCard}
                      setIsShowFilterCard={setIsShowFilterCard}
                    />
                  </span>
                </Box>
              </Grid>
            }
            apiEnd={ApiEndpoints.GET_OPERATOR}
            searchOptions={searchOptions}
            setQuery={setQuery}
            columns={columns}
            apiData={apiData}
            setApiData={setApiData}
            tableStyle={CustomStyles}
            queryParam={query ? query : ""}
            returnRefetch={(ref) => {
              refresh = ref;
            }}
          />
          {/* <ApiPaginate
            apiEnd={ApiEndpoints.GET_OPERATOR}
            columns={columns}
            apiData={apiData}
            tableStyle={CustomStyles}
            setApiData={setApiData}
            ExpandedComponent=""
            queryParam={query ? query : ""}
            returnRefetch={(ref) => {
              refresh = ref;
            }}
            paginateServer={false}
            paginate={true}
          /> */}
        </div>
      </Grid>
      {user && user.role.toLowerCase() === "admin" && (
        <Grid
          className="filter-lg"
          xs={0}
          sm={isShowFilterCard ? 4 : 0.1}
          lg={isShowFilterCard ? 2.5 : 0.1}
        >
          <FilterCard
            fromOperatorPage
            ifTypeFilter
            ifnameFilter
            ifrouteFilter
            ifstatusFilter
            // getRouteVal={getRouteVal}
            typeList={typeList}
            getTypes={getTypes}
            routeList={routeList}
            statusList={statusList}
            // getOperatorVal={getOperatorVal}
            setQuery={setQuery}
            query={query}
            clearHookCb={(cb) => {
              refreshFilter = cb;
            }}
            refresh={refresh}
            isShowFilterCard={isShowFilterCard}
            setIsShowFilterCard={setIsShowFilterCard}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default AdminOperatorView;
