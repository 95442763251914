import React, { useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  TextField,
  IconButton,
  Tooltip,
  Snackbar,
  Grid,
  Typography,
} from "@mui/material";
import ApiEndpoints from "../network/ApiEndPoints";
import { dateDifference, datemonthYear } from "../utils/DateUtils";
import { CustomStyles } from "../component/CostomStyle";
import BlockUnBlockModal from "../modals/BlockUnBlockModal";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { get } from "../network/ApiController";
import { apiErrorToast } from "../utils/ToastUtil";
import CachedIcon from "@mui/icons-material/Cached";
import { useEffect } from "react";
import useDebounce from "../utils/Debounce";
import { useContext } from "react";
import AuthContext from "../store/AuthContext";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import MoneyTransferModal from "../modals/MoneyTransferModal";
import DmtModal from "../modals/DmtModal";
import AddRetailerinAdUser from "../modals/AddRetailerinAdUser";
import ApiPaginateSearch from "../component/ApiPaginateSearch";
import { currencySetter } from "../utils/Currencyutil";
import { json2Csv, json2Excel } from "../utils/exportToExcel";
// import Spinner from "../commons/Spinner";
// import { excelIcon } from "../iconsImports";
import ExcelUploadModal from "../modals/ExcelUploadModal";
import moment from "moment";
import Spinner from "../commons/Spinner";
import {
  getActiveColor,
  // getSecondaryColor,
  // getTertiaryColor,
} from "../theme/setThemeColor";
import { useLocation } from "react-router-dom";
import UserServiceSetting from "../modals/UserServiceSetting";
import ViewUserModal from "../modals/ViewUserModal";
import AdminDocsViewModal from "../../src/modals/AdminDocsViewModal";
import WalletDebitModal from "../modals/WalletDebitModal";
import AsmProductSaleModal from "../modals/admin/AsmProductSaleModal";
import BarChartIcon from "@mui/icons-material/BarChart";
import { ROLE_LIST, ROLE_LIST4AD } from "../utils/constants";
import FilterCard from "../modals/FilterCard";

let handleCloseModal;
const AdimUserView = () => {
  const [apiData, setApiData] = useState([]);
  const [query, setQuery] = useState();

  const [asmList, setAsmList] = useState([]);
  // console.log("asmList", asmList);
  const [open, setOpen] = useState(false);
  // const [search, setSearch] = useState("");
  const [adVal, setAdVal] = useState([]);
  // console.log("ad", adVal);
  const authCtx = useContext(AuthContext);
  const user = authCtx.user;
  const [roleType, setRoleType] = useState("Role");
  const [defaultAsm, setDefaultAsm] = useState("Asm");
  const [defaultParent, setDefaultParent] = useState("parent");

  const [request, setRequest] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [noOfResponses, setNoOfResponses] = useState(0);
  const [userRequest, setUserRequest] = useState(false);

  const location = useLocation();
  // console.log(
  //   "location",
  //   location && location.state && location.state.username
  // );
  let refresh;
  function refreshFunc(setQuery) {
    setDefaultAsm("Asm");
    setRoleType("Role");
    setDefaultParent("parent");
    setQuery();
    if (refresh) refresh();
  }
  // useEffect(() => {
  //   setQuery(`establishment=${SearchEst}`);
  // }, [SearchEst]);

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const copyToClipBoard = (copyMe) => {
    try {
      navigator.clipboard.writeText(copyMe);
    } catch (err) {
      console.log(err);
    }
  };

  const getAsmValue = () => {
    get(
      ApiEndpoints.GET_USERS,
      `page=1&paginate=10&role=Asm&export=`,
      "",
      (res) => {
        const asmArray = res.data.data;
        setAsmList(
          asmArray &&
            asmArray.map((item) => {
              return {
                id: item.id,
                name: item.name,
              };
            })
        );
      },
      (error) => {
        apiErrorToast(error);
      }
    );
  };
  const getAdValue = () => {
    get(
      ApiEndpoints.GET_USERS,
      `page=1&paginate=10&role=Ad&export=`,
      "",
      (res) => {
        const adArray = res.data.data;
        setAdVal(
          adArray &&
            adArray.map((item) => {
              return {
                id: item.id,
                name: item.establishment,
              };
            })
        );
      },
      (error) => {
        apiErrorToast(error);
      }
    );
  };

  const getExcel = () => {
    get(
      ApiEndpoints.GET_USERS,
      `${
        query
          ? query + "&page=1&paginate=10&export=1"
          : "page=1&paginate=10&export=1"
      }`,
      setRequest,
      (res) => {
        const apiData = res.data.data;
        const newApiData = apiData.map((item) => {
          const created_at = moment(item.created_at).utc().format("DD-MM-YYYY");
          const updated_at = moment(item.updated_at).utc().format("DD-MM-YYYY");
          return { ...item, created_at, updated_at };
        });
        json2Excel(
          `Users ${moment(new Date().toJSON()).format(
            "Do MMM YYYY"
          )} | ${moment(new Date().toJSON()).format("hh:mm a")}`,
          JSON.parse(JSON.stringify(newApiData && newApiData))
        );
        handleCloseModal();
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  const getCsv = () => {
    get(
      ApiEndpoints.GET_USERS,
      `${
        query
          ? query + "&page=1&paginate=10&export=1"
          : "page=1&paginate=10&export=1"
      }`,
      setRequest,
      (res) => {
        const apiData = res.data.data;
        const newApiData = apiData.map((item) => {
          const created_at = moment(item.created_at).format("DD-MM-YYYY");
          const time_updated_at = moment(item.updated_at).format("LTS");
          return { ...item, created_at, time_updated_at };
        });
        json2Csv(
          `Users ${moment(new Date().toJSON()).format(
            "Do MMM YYYY"
          )} | ${moment(new Date().toJSON()).format("hh:mm a")}`,
          JSON.parse(JSON.stringify(newApiData && newApiData))
        );
        handleCloseModal();
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  useEffect(() => {
    getAsmValue();
    getAdValue();
  }, []);

  const handleChangeRole = (event) => {
    setRoleType(event.target.value);
    if (event.target.value !== "Role") {
      if (query && query.includes("&asm=") && query.includes("&role=")) {
        setQuery(`&role=${event.target.value}&asm=${defaultAsm}`);
      } else {
        query
          ? setQuery(query + `&role=${event.target.value}`)
          : setQuery(`role=${event.target.value}`);
      }

      // if (query && query.includes("&role=")) {
      //   setQuery(`&role=${event.target.value}`);
      // } else {
      //   query
      //     ? setQuery(query + `&role=${event.target.value}`)
      //     : setQuery(`&role=${event.target.value}`);
      // }
      //
    } else if (event.target.value === "Role") setQuery(`role=`);
    // } else if (event.target.value === "Role") setQuery(null);
  };
  //
  const handleChangeAsm = (event) => {
    setDefaultAsm(event.target.value);
    if (event.target.value !== "Asm") {
      //
      if (query && query.includes("&asm=") && query.includes("&role=")) {
        setQuery(`&asm=${event.target.value}&role=${roleType}`);
      } else {
        query
          ? setQuery(query + `&asm=${event.target.value}`)
          : setQuery(`asm=${event.target.value}`);
        // setQuery(query + `&asm=${event.target.value}`);
      }
      //

      // query
      //   ? setQuery(query + `&asm=${event.target.value}`)
      //   : setQuery(`&asm=${event.target.value}`);
    } else if (event.target.value === "Asm") setQuery(`asm=`);
  };

  const handleChangeParent = (e) => {
    setDefaultParent(e.target.value);
    if (e.target.value !== "default") {
      if (query && query.includes("&parent=") && query.includes("&role=")) {
        setQuery(`&parent=${e.target.value}&role=${roleType}`);
      } else {
        setQuery(`parent=${e.target.value}`);
      }
    }
  };

  useEffect(() => {
    if (location.state) {
      setQuery(`username=${location.state.username}`);
    }
  }, [location.state]);

  //
  const getParent = (row, parent = false, asm = false) => {
    if (parent) {
      let item =
        adVal && adVal.find((item) => item.id === parseInt(row.parent));
      if (item) {
        return item.name;
      } else {
        return "";
      }
    } else if (asm) {
      let item = asmList && asmList.find((item) => item.id === Number(row.asm));
      // return item && item.name
      if (item) {
        return item.name;
      } else {
        return "";
      }
    }
  };
  // #############table columns################ //
  const columns = [
    {
      name:
        user && user.role === "Admin"
          ? "ID/Date"
          : user && user.role === "Asm"
          ? "ID/Date"
          : "Date",
      selector: (row) => (
        <>
          <div
            hidden={
              user && user.role === "Admin"
                ? false
                : user && user.role === "Asm"
                ? false
                : true
            }
            style={{ marginBottom: "5px", textAlign: "left" }}
          >
            {row.id}
          </div>
          <div style={{ textAlign: "left" }}>
            {datemonthYear(row.created_at)}
          </div>
        </>
      ),
      width: "120px",
    },
    // {
    //   name: "Date",
    //   selector: (row) => <div>{datemonthYear(row.created_at)}</div>,
    //   wrap: true,
    // },
    {
      name: "Mobile",
      selector: (row) => (
        <>
          <div
            onClick={() => {
              copyToClipBoard(row.username);
              handleClickSnack();
            }}
            style={{ textAlign: "left" }}
          >
            {row.username}
          </div>
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleCloseSnack}
            message="number copied"
            sx={{ zIndex: 10000 }}
          />

          {user && user.role === "Admin" && (
            <div
              style={{
                marginTop: "12px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  color: getActiveColor(),
                  mr: 2,
                }}
                onClick={() => {
                  copyToClipBoard(row.instId);
                  handleClickSnack();
                }}
              >
                {row.instId}
              </Typography>
              {row.rt_code && (
                <Typography
                  sx={{
                    fontSize: "10px",
                    color: "#000",
                    py: 0.5,
                    px: 0.6,
                    background: "#d5d4e6",
                    borderRadius: "4px",
                  }}
                >
                  {row.rt_code}
                </Typography>
              )}
            </div>
          )}
        </>
      ),
      wrap: true,
      width: "180px",
    },
    // {
    //   name: "Name",
    //   selector: (row) => (
    //     <div>
    //       <span
    //         style={{
    //           whiteSpace: "nowrap",
    //           overflow: "hidden",
    //           textOverflow: "clip",
    //         }}
    //       >
    //         {row.name}
    //       </span>
    //     </div>
    //   ),

    //   wrap: true,
    //   width: "120px",
    // },
    {
      name: "Name",
      selector: (row) => (
        <div>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "clip",
              textAlign: "left",
              marginBottom: "8px",
            }}
          >
            {row.name}
          </div>
          <div
            style={{
              whiteSpace: "break-spaces",
              overflow: "hidden",
              textOverflow: "clip",
              textAlign: "left",
              fontSize: "10px",
              color: getActiveColor(),
            }}
          >
            {row.establishment}
          </div>
        </div>
      ),

      wrap: true,
      width: "140px",
    },
    // {
    //   name: "EST",
    //   selector: (row) => (
    //     <div
    //       style={{
    //         whiteSpace: "break-spaces",
    //         overflow: "hidden",
    //         textOverflow: "clip",
    //         textAlign: "left",
    //       }}
    //     >
    //       {row.establishment}
    //     </div>
    //   ),
    //   wrap: true,
    //   center: false,
    //   grow: 1.3,
    // },
    {
      name:
        user && user.role === "Ad" ? (
          <FormControl
            className="customized-textfield "
            fullWidth
            sx={{ textAlign: "left" }}
          >
            <TextField
              select
              defaultValue={roleType && roleType}
              onChange={handleChangeRole}
              sx={{ color: "#fff" }}
            >
              <MenuItem dense value="Role">
                Role
              </MenuItem>
              <MenuItem dense value="Api">
                Corporates
              </MenuItem>
              <MenuItem dense value="Ret">
                Retailer
              </MenuItem>
            </TextField>
          </FormControl>
        ) : (
          <FormControl
            className="customized-textfield"
            fullWidth
            sx={{ textAlign: "left" }}
          >
            <TextField
              select
              defaultValue={roleType}
              onChange={handleChangeRole}
              sx={{ color: "#fff" }}
            >
              <MenuItem dense value="Role">
                Role
              </MenuItem>
              <MenuItem dense value="Admin">
                Admin
              </MenuItem>
              <MenuItem dense value="Zsm">
                Zonal Sales Manager
              </MenuItem>
              <MenuItem dense value="Md">
                Master Distributer
              </MenuItem>
              <MenuItem dense value="Api">
                Corporates
              </MenuItem>
              <MenuItem dense value="Asm">
                Sales Manager
              </MenuItem>
              <MenuItem dense value="Ad">
                Area Distributor
              </MenuItem>
              <MenuItem dense value="Dd">
                Direct Dealer
              </MenuItem>
              <MenuItem dense value="Ret">
                Retailer
              </MenuItem>
            </TextField>
          </FormControl>
        ),
      selector: (row) => (
        <span
          className="break-words"
          style={{
            textAlign: "left",
            display: "flex",
            justifyContent: "flex-start",
            paddingLeft: "13px",
          }}
        >
          {row.role && row.role === "Ret"
            ? "Retailer"
            : row.role && row.role === "Ad"
            ? "Area Distributer"
            : row.role && row.role === "Admin"
            ? "Admin"
            : row.role && row.role === "Api"
            ? "Corporates"
            : row.role && row.role === "Asm"
            ? "Sales Manager"
            : row.role && row.role === "Dd"
            ? "Direct Dealer"
            : row.role && row.role === "Md"
            ? "Master Distributer"
            : row.role && row.role === "Zsm"
            ? "Zonal Sales Manager"
            : ""}
        </span>
      ),
      wrap: true,
      center: false,
      omit:
        user && user.role === "Admin"
          ? false
          : user && user.role === "Asm"
          ? false
          : true,
    },
    // {
    //   name: "Parent",
    //   cell: (row) => (
    //     <div
    //       style={{
    //         whiteSpace: "break-spaces",
    //         overflow: "hidden",
    //         textOverflow: "clip",
    //         textAlign: "left",
    //         // paddingLeft: "13px",
    //       }}
    //     >
    //       {/* getParent(row, parent, asm) */}
    //       {getParent(row && row, true, false)}
    //     </div>
    //   ),
    //   wrap: true,
    //   center: false,
    //   grow: 1.7,
    //   omit:
    //     user && user.role === "Admin"
    //       ? false
    //       : user && user.role === "Asm"
    //       ? false
    //       : true,
    // },
    {
      name: (
        <FormControl className="customized-textfield" fullWidth>
          <TextField
            select
            value={defaultParent && defaultParent}
            onChange={handleChangeParent}
            sx={{ color: "#fff" }}
          >
            <MenuItem dense value="parent">
              Parent
            </MenuItem>
            {adVal &&
              adVal.map((item, index) => {
                return (
                  <MenuItem key={index} dense value={item.id}>
                    {item && item.name}
                  </MenuItem>
                );
              })}
          </TextField>
        </FormControl>
      ),
      cell: (row) => (
        <div
          style={{
            whiteSpace: "break-spaces",
            overflow: "hidden",
            textOverflow: "clip",
            textAlign: "left",
            // paddingLeft: "13px",
          }}
        >
          {/* getParent(row, parent, asm) */}
          {getParent(row && row, true, false)}
        </div>
      ),
      wrap: true,
      center: false,
      grow: 1.7,
      omit:
        user && user.role === "Admin"
          ? false
          : user && user.role === "Asm"
          ? false
          : true,
    },
    {
      name: "InActive",
      cell: (row) => (
        <span>{dateDifference(row.updated_at, new Date())} days</span>
      ),
      wrap: true,
      width: "80px",
      omit: user && user.role === "Ret" ? true : false,
    },
    {
      name: (
        <FormControl className="customized-textfield" fullWidth>
          <TextField
            select
            value={defaultAsm && defaultAsm}
            onChange={handleChangeAsm}
            sx={{ color: "#fff" }}
          >
            <MenuItem dense value="Asm">
              Asm
            </MenuItem>
            {asmList &&
              asmList.map((item, index) => {
                return (
                  <MenuItem key={index} dense value={item.id}>
                    {item && item.name}
                  </MenuItem>
                );
              })}
          </TextField>
        </FormControl>
      ),
      cell: (row) => (
        <div
          style={{
            whiteSpace: "break-spaces",
            overflow: "hidden",
            textOverflow: "clip",
            textAlign: "left",
            paddingLeft: "13px",
          }}
        >
          {/* getParent(row, parent, asm) */}
          {getParent(row && row, false, true)}
        </div>
      ),
      wrap: true,
      grow: 2,
      omit: user && user.role === "Admin" ? false : true,
    },
    {
      name: "Wallet balance",
      selector: (row) => (
        <div style={{ textAlign: "left" }}>
          <div>{currencySetter(row.w1 / 100)}</div>
          <div style={{ color: "#199ebb" }}>{currencySetter(row.w2 / 100)}</div>
          <div>
            {row.hold && row.hold > 0 ? (
              <span style={{ color: "red" }}>{currencySetter(row.hold)}</span>
            ) : (
              ""
            )}
          </div>
        </div>
      ),
      grow: 1,
      center: false,
    },
    // {
    //   name: "KYC",
    //   selector: (row) => (
    //     <Box
    //       sx={{
    //         fontSize: "10px",
    //         color: row.kyc && row.kyc === 1 ? "#00BF78" : "#4E5555",
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         fontWeight: "bold",
    //       }}
    //     >
    //       {row.kyc && row.kyc === 1 ? (
    //         <Tooltip title="Kyc Done">
    //           <DoneAllIcon />
    //         </Tooltip>
    //       ) : (
    //         <Tooltip title="Kyc Pending">
    //           <MoreHorizIcon />
    //         </Tooltip>
    //       )}
    //     </Box>
    //   ),
    //   wrap: true,
    //   omit:
    //     user && user.role === "Admin"
    //       ? false
    //       : user && user.role === "Asm"
    //       ? false
    //       : true,
    // },
    {
      name: "Documents",
      selector: (row) => <AdminDocsViewModal row={row} />,
      // width: "100px",
      omit: user && user.role !== "Admin",
    },
    {
      name: <span className="ms-2">DMT</span>,
      selector: (row) =>
        user && user.role === "Asm" ? (
          <span>{Number(row.dmt_slab2).toFixed(2)}%</span>
        ) : (
          <DmtModal row={row} refresh={refresh} />
        ),
      // omit: user && user.role === "Ad" ? false : true,
      center: false,
    },
    {
      name: "Transfer",
      selector: (row) => <MoneyTransferModal refresh={refresh} row={row} />,
      omit: user && user.role === "Ad" ? false : true,
    },
    {
      name:
        user && (user.role === "Admin" || user.role === "Asm")
          ? "Status/KYC"
          : "Status",
      selector: (row) => (
        //
        <div style={{ display: "flex" }}>
          {user && user.role === "Admin" ? (
            <BlockUnBlockModal row={row} refresh={refresh} />
          ) : (
            <Box sx={{ width: "100%" }}>
              {row.status === 1 ? (
                <Tooltip title="Unblocked">
                  <LockOpenOutlinedIcon sx={{ color: "#00BF78" }} />
                </Tooltip>
              ) : (
                <Tooltip title="Blocked">
                  <LockOutlinedIcon sx={{ color: "#4E5555" }} />
                </Tooltip>
              )}
            </Box>
          )}

          {((user && user.role === "Admin") ||
            (user && user.role === "Asm")) && (
            <Box
              sx={{
                fontSize: "10px",
                color: row.kyc && row.kyc === 1 ? "#00BF78" : "#4E5555",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                ml: 1,
              }}
            >
              {row.kyc && row.kyc === 1 ? (
                <Tooltip title="Kyc Done">
                  <DoneAllIcon />
                </Tooltip>
              ) : (
                <Tooltip title="Kyc Pending">
                  <MoreHorizIcon />
                </Tooltip>
              )}
            </Box>
          )}
        </div>
      ),
    },
    // {
    //   name: "Edit user",
    //   selector: (row) => (
    //     <Box sx={{ display: "flex", justifyContent: "space-between" }}>
    //       <ViewUserModal
    //         row={row}
    //         refresh={refresh}
    //         setUserRequest={setUserRequest}
    //       />
    //     </Box>
    //   ),
    //   omit: user && user.role === "Admin" ? false : true,
    // },
    {
      name: "Actions",
      selector: (row) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {user && user.role === "Admin" && (
            <AsmProductSaleModal
              role={row.role}
              name={row.name}
              id={row.id}
              amount={
                <Tooltip title="Performace Report" placement="bottom">
                  <BarChartIcon sx={{ color: "#655487" }} />
                </Tooltip>
              }
              usedInUserTable
            />
          )}
          {user && user.role === "Admin" && (
            <ViewUserModal
              row={row}
              refresh={refresh}
              setUserRequest={setUserRequest}
              asmArray={asmList}
              adArray={adVal}
            />
          )}
          {user && user.role === "Admin" && (
            <WalletDebitModal row={row} refresh={refresh} />
          )}
          <UserServiceSetting row={row} />
        </Box>
      ),
      width: "150px",
      right: true,
      omit: user && user.role === "Admin" ? false : true,
    },
  ];

  const searchOptions = [
    { field: "EST", parameter: "establishment" },
    { field: "Mobile", parameter: "username" },
    {
      field: user?.role === "Ad" ? "" : "Outlet Id",
      parameter: user?.role === "Ad" ? "" : "instId",
    },
  ];
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Spinner loading={userRequest} />
      <ApiPaginateSearch
        showSearch={false}
        actionButtons={
          <Grid
            item
            md={4}
            sm={6}
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            {user && user.role !== "Ad" && (
              <ExcelUploadModal
                twobuttons="Download Csv"
                btn
                request={request}
                getExcel={getExcel}
                getCsv={getCsv}
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                noOfResponses={noOfResponses}
                setQuery={setQuery}
                // defaultQuery={"mobile"}
                // queryValue={mobile && mobile}
                handleCloseCB={(closeModal) => {
                  handleCloseModal = closeModal;
                }}
                roleDropDown
                roleAsmDown
                rolechangeFunc={handleChangeRole}
                asmchangeFunc={handleChangeAsm}
                userRole={user && user.role}
                roleHook={roleType}
                asmHook={defaultAsm}
                asmApiData={asmList}
              />
            )}
            <Tooltip title="refresh">
              <IconButton
                className=""
                aria-label="refresh"
                color="success"
                onClick={() => {
                  refreshFunc(setQuery);
                }}
              >
                <CachedIcon className="refresh-purple" />
              </IconButton>
            </Tooltip>
            {user && user.role === "Admin" ? (
              ""
            ) : user && user.role === "Asm" ? (
              ""
            ) : (
              <AddRetailerinAdUser refresh={refresh} />
            )}
          </Grid>
        }
        apiEnd={ApiEndpoints.GET_USERS}
        searchOptions={searchOptions}
        setQuery={setQuery}
        columns={columns}
        apiData={apiData}
        tableStyle={CustomStyles}
        setApiData={setApiData}
        // ExpandedComponent={expend}
        queryParam={query ? query : ""}
        returnRefetch={(ref) => {
          refresh = ref;
        }}
        responses={(val) => {
          setNoOfResponses(val);
        }}
        isFilterAllowed
        filterComponent={
          <FilterCard
            showSearch={false}
            query={query}
            setQuery={setQuery}
            ifRoleFilter
            ifAdIdFilter
            ifestFilter
            ifUsernameFilter
            ifInstIdFilter
            ifFingIdFilter
            ifAsmFilter={user?.role === "Admin"}
            roleList={user?.role === "Ad" ? ROLE_LIST4AD : ROLE_LIST}
            asmList={asmList}
            clearHookCb={(cb) => {
              refresh = cb;
            }}
            refresh={refresh}
            // buttons
          />
        }
      />
    </Box>
  );
};

export default AdimUserView;
