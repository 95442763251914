import { postJsonData } from "../network/ApiController";

export const jsonUpload = (
  data,
  setRequest,
  onDone,
  onError,
  apiEnd = "",
  post
) => {
  if (post) {
    postJsonData(
      apiEnd,
      data,
      (flag) => {
        setRequest(flag);
      },
      (res) => {
        if (onDone) onDone(res.data);
      },
      (error) => {
        if (onError) onError(error);
      }
    );
  } else {
  }
};
