import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { useCallback } from "react";
import { useState } from "react";

import { useEffect } from "react";
import DataTable from "react-data-table-component";

import { useContext } from "react";
import AuthContext from "../store/AuthContext";
import { postJsonData } from "../network/ApiController";
import ApiEndpoints from "../network/ApiEndPoints";
import RefreshComponent from "./RefreshComponent";
import Mount from "./Mount";
import Spinner from "../commons/Spinner";
import { CustomStyles } from "./CostomStyle";
import { USER_ROLES } from "../utils/constants";

const gridItemStyle = {
  height: "auto",
  padding: "1.3rem",
  background: "#fff",
  borderRadius: "8px",
  boxShadow:
    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
  ml: { md: 0, xs: 0 },
  mr: { md: 1.9, xs: 0 },
};

const MyEarnings = () => {
  const [earnings, setEarnings] = useState([]);
  // console.log("earnings", earnings);
  const [type, setType] = useState("TODAY");
  const [request, setRequest] = useState(false);
  const authCtx = useContext(AuthContext);
  const user = authCtx.user;
  const role = user.role;

  const getEarning = useCallback(() => {
    postJsonData(
      ApiEndpoints.MY_EARNINGS,
      { type: type },
      setRequest,
      (res) => {
        if (res?.data?.data === "No Data Found") {
          setEarnings([]);
        } else {
          setEarnings(res?.data?.data);
        }
      },
      (err) => {}
    );
  }, [type]);

  useEffect(() => {
    getEarning();

    return () => {};
  }, [getEarning]);

  const columns = [
    {
      name: "Service",
      cell: (row) => row.SERVICE,
    },
    {
      name: "Commision",
      cell: (row) => row.COMMISSION,
    },
    {
      name: "TDS",
      cell: (row) => row.TDS,
      omit: role === USER_ROLES.ADMIN,
    },
  ];

  //   if (earnings && earnings.length > 0) {
  //     return {
  //       columns: [
  //         {
  //           name: "Commision",
  //           cell: (row) => row.COMMISSION,
  //         },
  //         {
  //           name: "Service",
  //           cell: (row) => row.SERVICE,
  //         },
  //         {
  //           name: "TDS",
  //           cell: (row) => row.TDS,
  //           omit: role === USER_ROLES.ADMIN,
  //         },
  //       ],
  //     };
  //   }
  // }, [earnings, role]);
  const handleChange = (event, newType) => {
    setType(newType);
  };

  return (
    <Grid
      container
      sx={{
        mt: 1.2,
        pr: { xs: 1.3, lg: 0 },
        mb: { xs: 8, lg: 0 },
      }}
      p
    >
      <Grid item xs={12} sx={gridItemStyle}>
        <Typography
          style={{
            fontWeight: "500",
            fontSize: "18px",
            display: "flex",
            alignContent: "center",
          }}
        >
          My Earnings
          <RefreshComponent onClick={getEarning} color="#000" />
        </Typography>
        <Grid
          item
          xs={12}
          sx={{
            mt: 2,
            mb: 2,
            textAlign: "left",
          }}
        >
          <ToggleButtonGroup
            color="secondary"
            defaultValue={type}
            value={type}
            exclusive
            onChange={handleChange}
            aria-label="value-type"
          >
            <ToggleButton size="small" value="TODAY">
              Today
            </ToggleButton>
            <ToggleButton size="small" value="THIS">
              This
            </ToggleButton>
            <ToggleButton size="small" value="LAST">
              Last
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Mount visible={earnings && earnings?.length > 0}>
          <Grid
            item
            xs={12}
            sx={{
              height: "max-content",
              minHeight: "450px",
              objectFit: "cover",
              position: "relative",
            }}
          >
            <Spinner loading={request} circleBlue />

            <DataTable
              columns={columns}
              data={earnings}
              customStyles={CustomStyles}
              pagination
              paginationServer={false}
              progressPending={request}
            />
          </Grid>
        </Mount>
      </Grid>
    </Grid>
  );
};

export default MyEarnings;
