import XLSX from "xlsx";
import { okErrorToast } from "./ToastUtil";
import ApiEndpoints from "../network/ApiEndPoints";
import { postJsonData, putJsonData } from "../network/ApiController";

export const readFileToJsonPromise = (e, setData) => {
  return new Promise((resolve, reject) => {
    var data = new Uint8Array(e.target.result);
    // console.log("edata=>", data);
    var workbook = XLSX.read(data, { type: "array" });
    // console.log("workbook", workbook);
    const wsname = workbook.SheetNames[0];
    // console.log("worksheet name", wsname);
    const ws = workbook.Sheets[wsname];
    // console.log("worksheet", ws);
    /* Convert array of arrays */
    const jsonData = XLSX.utils.sheet_to_json(ws);
    // console.log("jsondata=>", jsonData);
    /* Update state */
    // console.log("Data>>>", jsonData);
    // setData(jsonData);
    /* DO SOMETHING WITH workbook HERE */
    okErrorToast("File Imported successfully");
    // setLoading(false);
    resolve(
      jsonData
    ); /* return result here or you can use reject for execute catch block*/
  });
};

export const recursiveFileTransfer = (
  index,
  length,
  tempData,
  data,
  setRequest,
  onPartialSuccess,
  onSuccess,
  onComplete,
  onFail,
  apiEnd = "",
  post
) => {
  if (index > length - 1) {
    setRequest(true);
    onComplete(data);
    onSuccess(data);
    setRequest(false);
    return;
  }
  const obj = tempData[index];
  const meta = obj?.meta ? obj?.meta : null;
  if (post) {
    if (apiEnd === ApiEndpoints.COURIERS) {
      const id = data[index].hasOwnProperty("id");
      if (id) {
        if (meta) {
          meta.res = data[index];
          meta.api_status = "SUCCESS";
        }
        recursiveFileTransfer(
          index + 1,
          length,
          tempData,
          data,
          setRequest,
          onPartialSuccess,
          onSuccess,
          onComplete,
          onFail,
          apiEnd,
          true
        );
      }
      if (!id) {
        // console.log("not id");
        postJsonData(
          apiEnd,
          data[index],
          (flag) => {
            if (meta) meta.processing = flag;
            setRequest(flag);
          },
          (res) => {
            if (meta) {
              meta.res = res.data;
              meta.api_status = "SUCCESS";
            }
            onPartialSuccess(index, obj);
            // console.log("meta res=>", res);
            recursiveFileTransfer(
              index + 1,
              length,
              tempData,
              data,
              setRequest,
              onPartialSuccess,
              onSuccess,
              onComplete,
              onFail,
              apiEnd,
              true
            );
          },
          (error) => {
            // onFail(index, error);
            if (meta) {
              meta.res = error?.response?.data?.msg;
              meta.api_status = "FAILED";
            }
            onPartialSuccess(index, obj);
            recursiveFileTransfer(
              index + 1,
              length,
              tempData,
              data,
              setRequest,
              onPartialSuccess,
              onSuccess,
              onComplete,
              onFail,
              apiEnd,
              true
            );
          }
        );
      }
    } else
      postJsonData(
        apiEnd,
        data[index],
        (flag) => {
          if (meta) meta.processing = flag;
          setRequest(flag);
        },
        (res) => {
          if (meta) {
            meta.res = res.data;
            meta.api_status = "SUCCESS";
          }
          onPartialSuccess(index, obj);
          // console.log("meta res=>", res);
          recursiveFileTransfer(
            index + 1,
            length,
            tempData,
            data,
            setRequest,
            onPartialSuccess,
            onSuccess,
            onComplete,
            onFail,
            apiEnd,
            true
          );
        },
        (error) => {
          // onFail(index, error);
          if (meta) {
            meta.res = error?.response?.data?.msg;
            meta.api_status = "FAILED";
          }
          onPartialSuccess(index, obj);
          recursiveFileTransfer(
            index + 1,
            length,
            tempData,
            data,
            setRequest,
            onPartialSuccess,
            onSuccess,
            onComplete,
            onFail,
            apiEnd,
            true
          );
        }
      );
  } else
    putJsonData(
      apiEnd,
      data[index],
      "",
      (flag) => {
        if (meta) meta.processing = flag;
        setRequest(flag);
      },
      (res) => {
        if (meta) {
          meta.res = res.data.msg;
          meta.api_status = "SUCCESS";
        }
        onPartialSuccess(index, obj);
        recursiveFileTransfer(
          index + 1,
          length,
          tempData,
          data,
          setRequest,
          onPartialSuccess,
          onSuccess,
          onComplete,
          onFail,
          apiEnd,
          false
        );
      },
      (error) => {
        // onFail(index, error);
        if (meta) {
          meta.res = error?.response?.data?.msg;
          // meta.api_status = "FAILED";
          meta.api_status = error?.response?.data.detail
            ? `FAILED_${error?.response?.data.detail}`
            : "FAILED";
        }
        // console.log("error=>", error);
        // console.log(
        //   `success ${JSON.stringify(index)} --- ${JSON.stringify(obj)}`
        // );
        onPartialSuccess(index, obj);
        recursiveFileTransfer(
          index + 1,
          length,
          tempData,
          data,
          setRequest,
          onPartialSuccess,
          onSuccess,
          onComplete,
          onFail,
          apiEnd,
          false
        );
      }
    );
};
