import React from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Grid, Tooltip, Typography } from "@mui/material";
import Spinner from "../commons/Spinner";
import { currencySetter } from "../utils/Currencyutil";
import RefreshIcon from "@mui/icons-material/Refresh";

const DashboardDataComponent1 = ({
  users,
  data,
  index,
  len,
  w1 = "",
  w2 = "",
  getWalletBal,
  getBankBal,
  getPrimaryBalance,
  getTertiaryBalance,
  walletReq,
  bankBalReq,
  PrimaryRequest = false,
  TertiaryRequest = false,
}) => {
  // console.log("w1in comp", w1);
  // console.log("w2in comp", w2);

  const apiRefresh = () => {
    if (users && users.name.toLowerCase() === "wallet balance") {
      if (getWalletBal) getWalletBal();
    } else if (users && users.name.toLowerCase() === "bank balance") {
      if (getBankBal) getBankBal();
    } else if (users && users.name.toLowerCase() === "primary") {
      if (getPrimaryBalance) getPrimaryBalance();
    } else if (users.name.toLowerCase() === "tertiary") {
      if (getTertiaryBalance) getTertiaryBalance();
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderRight: {
            lg:
              data === "txn"
                ? ""
                : index === len - 1
                ? ""
                : "1px solid #DADADA",
            md:
              data === "txn"
                ? ""
                : index === len - 1
                ? ""
                : "1px solid #DADADA",
            xs: "",
            sm: "",
          },
        }}
        className="position-relative"
      >
        {index === 0 && data === "wallet" && (
          <Spinner loading={PrimaryRequest} />
        )}
        {index === 1 && data === "wallet" && (
          <Spinner loading={TertiaryRequest} />
        )}
        {index === 2 && data === "wallet" && <Spinner loading={walletReq} />}
        {index === 3 && data === "wallet" && (
          <Spinner loading={bankBalReq ? bankBalReq : false} />
        )}
        <Grid
          item
          onClick={() => apiRefresh()}
          sx={{ "&:hover": { cursor: "pointer" } }}
        >
          <div
            style={{
              fontSize: data === "txn" ? "15px" : "",
            }}
          >
            {users.name}{" "}
            {data !== "txn" && (
              <RefreshIcon
                className="refresh-purple"
                sx={{
                  fontSize: "16px",
                }}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <BarChartIcon sx={{ mr: 1, color: users.color }} />
            <Tooltip
              title={
                data === "txn" ? (
                  ""
                ) : users.name === "Wallet Balance" ? (
                  <>
                    <div>w1: {currencySetter(w1)}</div>
                    <div>w2: {currencySetter(w2)}</div>
                  </>
                ) : (
                  currencySetter(users.balance)
                )
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "baseline",
                }}
              >
                <Typography sx={{ fontSize: data === "txn" ? "18px" : "24px" }}>
                  {data === "txn"
                    ? users.balance
                    : Number(users.balance / 10000000).toFixed(2)}
                </Typography>
                {data !== "txn" && (
                  <div
                    style={{
                      marginLeft: "2px",
                      fontSize: "12px",
                    }}
                  >
                    Cr
                  </div>
                )}
              </div>
            </Tooltip>
          </div>
        </Grid>
        <div hidden={!users?.profit} style={{ fontSize: "12px" }}>
          Profit:{" "}
          <span style={{ color: "#00bf78" }}>
            {currencySetter(users?.profit)}
          </span>
        </div>
      </Grid>
    </>
  );
};

export default DashboardDataComponent1;
