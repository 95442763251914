import { Box, Container } from "@mui/material";
import React from "react";

const DactivateAccountPolicy = () => {
  return (
    <div>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <div className="landing-bg_PayZoom_font">Terms &amp; Conditions</div>
        <Box
          style={{
            width: "60px",
            height: "10px",
            backgroundColor: "#32AD6C",
          }}
        ></Box>
        <div>
          <div>
            <div>
              <p className="landing-bg_para">
                We would like you to read these Account Deactivation policy
                <strong>(Account Deactivation policy)</strong>
                carefully&nbsp;before you use our platform or any of its
                services
                <strong>(“Services”)</strong>. These Terms are legally binding
                and govern the use of our website, our mobile application, our
                API and other properties collectively called as the
                <strong>“PayZoom Platform”</strong> or
                <strong> “Platform”</strong>
                created by PayZoom Solutions Private Limited Pvt. Ltd., a
                company incorporated under the laws of India, with its
                registered office at B1/A5, Mohan Co-operative Industrial
                Estate, New Delhi - 110044
                <strong>(“PayZoom” or “Company”)</strong>.
              </p>

              <div className="landing-bg_para">
                <h3 className="h5">
                  <abbr>Account Deactivation Request</abbr>
                </h3>
                <p className="landing-bg_para">
                  Users of PayZoom have the option to request the deactivation
                  of their accounts. This policy outlines the procedure for
                  initiating and completing the account deactivation process.
                </p>
              </div>

              <div className="landing-bg_para">
                <h3>
                  <abbr>How to Request Account Deactivation</abbr>
                </h3>
                <p className="landing-bg_para">
                  Account Deactivation request is carried through admins only
                </p>
              </div>

              <div className="landing-bg_para">
                <h3 className="h5">
                  <abbr>Verification of Identity</abbr>
                </h3>

                <p className="landing-bg_para">
                  For security purposes, PayZoom may require verification of
                  your identity before processing your account deactivation
                  request. You may be asked to provide information such as your
                  name, email address, or other details associated with your
                  account.
                </p>

                <p className="landing-bg_para">
                  <strong>“Applicable Law”</strong>
                  includes all applicable Indian statutes, enactments, acts of
                  the state legislature or parliament, laws, ordinances, rules,
                  bye-laws, regulations, notifications, guidelines, directions,
                  directives and orders of any governmental authority, statutory
                  authority, board, recognized stock exchange, as may be
                  applicable.
                </p>

                <p className="landing-bg_para">
                  <strong>Account Deactivation Process</strong>
                  Upon receiving a valid account deactivation request and
                  verifying your identity, PayZoom will proceed with the account
                  deactivation process, which includes the following steps:
                </p>
                <p className="landing-bg_para">
                  a. Deactivation of account access: PayZoom will deactivate
                  your access to your account, preventing any further use.
                </p>
                <div className="landing-bg_para">
                  <div className="landing-bg_para">
                    b. Preservation of account data: Your account information
                    and associated data will be securely stored for a reasonable
                    period to comply with legal obligations or for auditing
                    purposes.
                  </div>
                </div>

                <p className="landing-bg_para">
                  <strong>Retention of Transaction Data</strong> means and
                  includes Please note that PayZoom may retain transaction data
                  associated with your account for a reasonable period to comply
                  with legal obligations or for auditing purposes. However, this
                  data will not be used for any other purposes.
                </p>

                <p className="landing-bg_para">
                  <strong>Timeframe for Account Deactivation</strong> or The
                  account deactivation process may take up to [10] business days
                  from the date of your request. During this time, your account
                  will be deactivated, and you will not have access to it.
                </p>

                <p className="landing-bg_para">
                  <strong>“PayZoom Policies”</strong> shall mean and include the
                  Terms of Use, Privacy Policy, Grievance Policy and any other
                  such terms and conditions/policies available and updated from
                  time to time on the PayZoom Platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DactivateAccountPolicy;
