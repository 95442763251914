import {
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import ApiPaginate from "./ApiPaginate";
import ApiEndpoints from "../network/ApiEndPoints";
import { massegetable } from "./CostomStyle";
import { useState } from "react";
import { currencySetter } from "../utils/Currencyutil";
import { getActiveColor } from "../theme/setThemeColor";
import useCommonContext from "../store/CommonContext";

let refresh;
function refreshFunc(setQueryParams, setPushFlag) {
  setQueryParams("");
  setPushFlag(true);
  if (refresh) refresh();
}
const AsmProductionSaleComponent = () => {
  const { setPushFlag } = useCommonContext();
  const [apiData, setApiData] = useState([]);
  const [query, setQuery] = useState();
  const [showPrimaryData, setShowPrimaryData] = useState(true);
  const columnsAsm = [
    {
      name: "Name",
      selector: (row) => (row.name ? row.name : "TOTAL"),
      width: "150px",
    },
    {
      name: "Last Month",
      selector: (row) =>
        row.primaryLast === 0
          ? currencySetter(10000000)
          : currencySetter(row.primaryLast),
    },
    {
      name: "This Month",
      selector: (row) => currencySetter(row.primaryThis),
    },
    {
      name: "Today",
      selector: (row) => currencySetter(row.primaryToday),
      width: "80px",
    },
    {
      name: "Achieved",
      selector: (row) => (
        <div>
          <div>
            {Number(
              (parseInt(row.primaryThis) * 100) / parseInt(row.primaryLast)
            ).toFixed(2) + "%"}
          </div>
          <div>
            <LinearProgress
              variant="determinate"
              value={
                Number(
                  (parseInt(row.primaryThis) * 100) / parseInt(row.primaryLast)
                ).toFixed(2) > 100
                  ? 100
                  : Number(
                      (parseInt(row.primaryThis) * 100) /
                        parseInt(row.primaryLast)
                    ).toFixed(2)
              }
            />
          </div>
        </div>
      ),
    },
  ];

  const columnsProd = [
    {
      name: "Services",
      selector: (row) => row.service,
    },
    {
      name: "Last Month",
      selector: (row) => currencySetter(row.Last),
      width: "130px",
    },

    {
      name: "This Month",
      selector: (row) => currencySetter(row.This),
      width: "130px",
    },
    {
      name: "Today",
      selector: (row) => currencySetter(row.Today),
      width: "130px",
    },

    {
      name: "Achieved",
      selector: (row) => (
        <div style={{ width: "100px" }}>
          <div>
            {Number(row.Last) === 0
              ? "0.00%"
              : Number((parseInt(row.This) * 100) / parseInt(row.Last)).toFixed(
                  2
                ) + "%"}
          </div>
          <div>
            <LinearProgress
              variant="determinate"
              value={
                Number((parseInt(row.This) * 100) / parseInt(row.Last)) > 100
                  ? 100
                  : Number(row.Last) === 0
                  ? 0
                  : Number((parseInt(row.This) * 100) / parseInt(row.Last))
              }
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Grid
      container
      lg={5.4}
      md={12}
      sm={11.8}
      xs={11.2}
      sx={{
        ml: { lg: 1, md: 0, xs: 0 },
        mr: { md: 0, xs: 0 },
        mt: { lg: 0, md: 1, sm: 1, xs: 1 },
        background: "#fff",
        borderRadius: "8px",
        padding: "1.3rem",
        display: "block",
        // height: "500px",
        // overflowY: "scroll",
        boxShadow:
          "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
      }}
      className="big-screen-box small-screen-box line-chart"
    >
      {/* asm production table */}

      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
          // backgroundColor: "red",
        }}
      >
        <Typography
          style={{
            fontWeight: "500",
            fontSize: "18px",
            display: "flex",
            alignContent: "center",
          }}
        >
          {showPrimaryData && showPrimaryData
            ? "Product Sale Table"
            : "ASM Primary"}

          <CachedOutlinedIcon
            className="ms-2 refresh-purple"
            sx={{
              transform: "scale(1)",
              transition: "0.5s",
              "&:hover": { transform: "scale(1.2)" },
              ml: 1,
            }}
            onClick={() => {
              refreshFunc(setQuery, setPushFlag);
            }}
          />
        </Typography>

        <FormGroup>
          <FormControlLabel
            sx={{
              mt: { md: 0, sm: 2, xs: 2 },
              mb: { md: 0, sm: 2, xs: 2 },
            }}
            control={
              <Switch
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: getActiveColor(),
                  },
                }}
                value={showPrimaryData}
                defaultChecked={showPrimaryData}
                onChange={() => setShowPrimaryData(!showPrimaryData)}
              />
            }
            label={
              <Typography variant="body2" style={{ fontSize: "15px" }}>
                Product Sale
              </Typography>
            }
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12} lg={12} sm={12} md={12} sx={{ mt: 2 }}>
        {showPrimaryData && showPrimaryData ? (
          <ApiPaginate
            apiEnd={ApiEndpoints.GET_RET_PROD_SALE}
            columns={columnsProd}
            apiData={apiData}
            tableStyle={massegetable}
            setApiData={setApiData}
            queryParam={query ? query : ""}
            returnRefetch={(ref) => {
              refresh = ref;
            }}
            ExpandedComponent={null}
            paginateServer={false}
            paginate={false}
          />
        ) : (
          <>
            <ApiPaginate
              apiEnd={ApiEndpoints.ASM_PRODUCTION_SALE_DATA}
              columns={columnsAsm}
              apiData={apiData}
              tableStyle={massegetable}
              setApiData={setApiData}
              queryParam={query ? query : ""}
              returnRefetch={(ref) => {
                refresh = ref;
              }}
              ExpandedComponent={null}
              paginateServer={false}
              paginate={false}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default AsmProductionSaleComponent;
