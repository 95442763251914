import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import RouterIcon from "@mui/icons-material/Router";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import MobileFriendlyOutlinedIcon from "@mui/icons-material/MobileFriendlyOutlined";
import TouchAppOutlinedIcon from "@mui/icons-material/TouchAppOutlined";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import SwipeRightOutlinedIcon from "@mui/icons-material/SwipeRightOutlined";
import BoltIcon from "@mui/icons-material/Bolt";
import BugReportIcon from "@mui/icons-material/BugReport";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AssessmentIcon from "@mui/icons-material/Assessment";
import {
  account_ledgre,
  aepsIcon,
  bbIcon,
  cardIcon,
  creditReq,
  dashboard,
  dthIcon,
  elecIcon,
  etIcon,
  gasIcon,
  khataBook,
  mtIcon,
  myComplaints,
  myPurchase,
  ntIcon,
  stIcon,
  transactions,
  utIcon,
  Electricity,
  FASTag,
  Cylinder,
  Postpaid,
  Prepaid,
  Gas,
  BBPS,
  licIcon,
  waterIcon,
  landIcon,
  flightBooking,
  hotelBooking,
  busBooking,
} from "./iconsImports";
export const nav = [
  {
    title: "Dashboard",
    icon: (
      <i class="material-symbols-outlined" style={{ fontSize: "25px" }}>
        {" "}
        person
      </i>
    ),
    to: "/dashboard",
  },

  {
    title: "Recharge",
    icon: (
      <i class="material-symbols-outlined" style={{ fontSize: "25px" }}>
        {" "}
        Shuffle
      </i>
    ),
    to: "/dashboard/transaction",
  },

  {
    title: "Money Transfer",
    icon: (
      <i class="material-symbols-outlined" style={{ fontSize: "25px" }}>
        {" "}
        Shuffle
      </i>
    ),
    to: "/dashboard/transaction",
  },

  {
    title: "Express Transfer",
    icon: (
      <i class="material-symbols-outlined" style={{ fontSize: "25px" }}>
        {" "}
        Shuffle
      </i>
    ),
    to: "/dashboard/transaction",
  },

  {
    title: "UPI Transfer",
    icon: (
      <i class="material-symbols-outlined" style={{ fontSize: "25px" }}>
        {" "}
        Shuffle
      </i>
    ),
    to: "/dashboard/transaction",
  },

  {
    title: "AEPS",
    icon: (
      <i class="material-symbols-outlined" style={{ fontSize: "25px" }}>
        {" "}
        Shuffle
      </i>
    ),
    to: "/dashboard/transaction",
  },

  {
    title: "Transaction",
    icon: (
      <i class="material-symbols-outlined" style={{ fontSize: "25px" }}>
        {" "}
        Shuffle
      </i>
    ),
    to: "/dashboard/transaction",
  },

  {
    title: "Credit Request",
    icon: (
      <i class="material-symbols-outlined" style={{ fontSize: "25px" }}>
        {" "}
        Shuffle
      </i>
    ),
    to: "/dashboard/transaction",
  },

  {
    title: "Contact Us",
    icon: (
      <i class="material-symbols-outlined" style={{ fontSize: "25px" }}>
        Shuffle
      </i>
    ),
    to: "/dashboard/transaction",
  },
];

export const Admin_nav = [
  {
    title: "Dashboard",
    icon: <HomeOutlinedIcon />,
    to: "/admin/dashboard",
  },
  {
    title: "Users",
    icon: <PersonOutlineOutlinedIcon />,
    to: "/admin/users",
  },
  {
    title: "Transactions",
    icon: <ShuffleIcon />,
    to: "/admin/transactions",
  },
  {
    title: "Fund Requests",
    icon: <CurrencyRupeeOutlinedIcon />,
    to: "/admin/cred-req",
  },
  {
    title: "Accounts",
    icon: <AssignmentIndOutlinedIcon />,
    to: "/admin/accounts",
  },
  {
    title: "Banks",
    icon: <AccountBalanceOutlinedIcon />,
    to: "/admin/banks",
  },
  {
    title: "Messages",
    icon: <MailOutlineIcon />,
    to: "/admin/messages",
  },
  {
    title: "Notifications",
    icon: <NotificationsIcon />,
    to: "/admin/notification",
  },
  {
    title: "Operators",
    icon: <RouterIcon />,
    to: "/admin/operators",
  },
  {
    title: "Routes",
    icon: <AltRouteIcon />,
    to: "/admin/routes",
  },
  {
    title: "Plans",
    icon: <AddToHomeScreenIcon />,
    to: "/admin/plans",
  },
  // {
  //   title: "ManualPost",
  //   icon: <WebhookIcon />,
  //   // to: "/admin/webhook",
  // },
  {
    title: "Complaint",
    icon: <BugReportIcon />,
    to: "/admin/complaints",
  },
];
export const Asm_nav = [
  {
    title: "Dashboard",
    icon: <HomeOutlinedIcon />,
    to: "/asm/dashboard",
  },
  {
    title: "Users",
    icon: <PersonOutlineOutlinedIcon />,
    to: "/asm/users",
  },
  {
    title: "Transactions",
    icon: <ShuffleIcon />,
    to: "/asm/transactions",
  },
  {
    title: "Credit Requests",
    icon: <CurrencyRupeeOutlinedIcon />,
    to: "/asm/cred-req",
  },
];
export const Api_nav = [
  {
    title: "Dashboard",
    icon: <HomeOutlinedIcon />,
    to: "/api-user/dashboard",
  },

  {
    title: "Transactions",
    icon: <ShuffleIcon />,
    to: "/api-user/transactions",
  },
  {
    title: "Credit Requests",
    icon: <CurrencyRupeeOutlinedIcon />,
    to: "/api-user/cred-req",
  },
];

export const Ad_nav = [
  {
    title: "Dashboard",
    icon: <HomeOutlinedIcon />,
    to: "/ad/dashboard",
  },
  {
    title: "Users",
    icon: <PersonOutlineOutlinedIcon />,
    to: "/ad/users",
  },
  {
    title: "Credit Requests",
    icon: <CurrencyRupeeOutlinedIcon />,
    to: "/ad/cred-req",
  },
  {
    title: "Transactions",
    icon: <ShuffleIcon />,
    to: "/ad/transactions",
  },
  {
    title: "My Sale",
    icon: <TrendingUpIcon />,
    to: "/ad/sale",
  },
  {
    title: "My Purchase",
    icon: <FontAwesomeIcon icon={faLink} style={{ fontSize: "18px" }} />,
    to: "/ad/purchase",
  },

  {
    title: "My Ledger",
    icon: <LibraryBooksIcon />,
    to: "/ad/ledger",
  },
  // {
  //   title: "Khata Book",
  //   icon: <MenuBookOutlinedIcon />,
  //   to: "/ad/khata-book",
  // },
  // {
  //   title: "Contact Us",
  //   icon: <SupportAgentIcon />,
  //   to: "/ad/cred-req",
  // },
];
// this is the nav for the old layout
export const customer_nav = [
  {
    title: "Dashboard",
    icon: <HomeOutlinedIcon />,
    to: "/customer/dashboard",
  },
  {
    title: "Recharge/Bill",
    icon: <MobileFriendlyOutlinedIcon />,
    to: "/customer/recharge",
  },
  {
    title: "BBPS",
    icon: <FormatBoldIcon />,
    to: "/customer/bbps",
  },
  {
    title: "Money Transfer",
    icon: <SwapHorizIcon />,
    to: "/customer/money-transfer",
  },
  {
    title: "Express Transfer",
    icon: <SwipeRightOutlinedIcon />,
    to: "/customer/express-transfer",
  },
  {
    title: "Super Transfer",
    icon: <BoltIcon />,
    to: "/customer/super-transfer",
  },
  {
    title: "Nepal Transfer",
    icon: <OutlinedFlagIcon />,
    to: "/customer/nepal-transfer",
  },
  {
    title: "UPI Transfer",
    icon: <QrCodeScannerIcon />,
    to: "/customer/upi-transfer",
  },
  {
    title: "AEPS",
    icon: <TouchAppOutlinedIcon />,
    to: "/customer/aeps",
  },
  {
    title: "Fund Request",
    icon: <CurrencyRupeeOutlinedIcon />,
    to: "/customer/cred-req",
  },
  {
    title: "Reports",
    icon: <AssessmentIcon />,
    to: "#",
    submenus: [
      {
        title: "My Transactions",
        icon: <ShuffleIcon />,
        to: "/customer/transactions",
      },
      {
        title: "Account Ledger",
        icon: <EventNoteIcon />,
        to: "/customer/account-ledger",
      },

      {
        title: "My Complaints",
        icon: <SupportAgentIcon />,
        to: "/customer/complaints",
      },
      {
        title: "My Purchase",
        icon: <FontAwesomeIcon icon={faLink} style={{ fontSize: "18px" }} />,
        to: "/customer/purchase",
      },
      // {
      //   title: "Khata Book",
      //   icon: <MenuBookOutlinedIcon />,
      //   to: "/customer/khata-book",
      // },
    ],
  },
];

// these are the navs for the new layout
export const reports = [
  {
    title: "Dashboard",
    icon: dashboard,
    to: "/customer/dashboard",
  },
  {
    title: "My Transactions",
    icon: transactions,
    to: "/customer/transactions",
  },
  {
    title: "Account Ledger",
    icon: account_ledgre,
    to: "/customer/account-ledger",
  },
  {
    title: "Fund Request",
    icon: creditReq,
    to: "/customer/cred-req",
  },
  {
    title: "My Complaints",
    icon: myComplaints,
    to: "/customer/complaints",
  },
  {
    title: "My Purchase",
    icon: myPurchase,
    to: "/customer/purchase",
  },
  // {
  //   title: "Khata Book",
  //   icon: khataBook,
  //   to: "/customer/khata-book",
  // },
];

export const banking = [
  {
    title: "Money Transfer",
    icon: mtIcon,
    to: "/customer/money-transfer",
  },
  {
    title: "Express Transfer",
    icon: etIcon,
    to: "/customer/express-transfer",
  },
  {
    title: "Super Transfer",
    icon: stIcon,
    to: "/customer/super-transfer",
  },
  {
    title: "AEPS",
    icon: aepsIcon,
    to: "/customer/aeps",
  },
  {
    title: "Nepal Transfer",
    icon: ntIcon,
    to: "/customer/nepal-transfer",
  },
  {
    title: "UPI Transfer",
    icon: utIcon,
    to: "/customer/upi-transfer",
  },
];

export const bbpsServices = [
  {
    title: "Electricity",
    icon: <FormatBoldIcon />,
    to: "/customer/bbps",
    isModal: true,
  },
  {
    title: "Gas Cylinder",
    icon: <FormatBoldIcon />,
    to: "/customer/bbps",
    isModal: true,
  },
  {
    title: "Mobile Postpaid",
    icon: <FormatBoldIcon />,
    to: "/customer/bbps",
    isModal: true,
  },
  {
    title: "Mobile Prepaid",
    icon: <FormatBoldIcon />,
    to: "/customer/bbps",
    isModal: true,
  },
  {
    title: "FASTag",
    icon: <FormatBoldIcon />,
    to: "/customer/bbps",
    isModal: true,
  },
  {
    title: "Piped Gas",
    icon: <FormatBoldIcon />,
    to: "/customer/bbps",
    isModal: true,
  },
  {
    title: "BBPS",
    icon: <FormatBoldIcon />,
    to: "/customer/bbps",
  },
];
export const ourServices = [
  {
    title: "Mobile Recharge",
    icon: Prepaid,
    to: "",
    isModal: true,
  },
  {
    title: "DTH Recharge",
    icon: dthIcon,
    to: "",
    isModal: true,
  },
  {
    title: "Electricity with commission",
    icon: elecIcon,
    to: "",
    isModal: true,
  },
  {
    title: "LIC Premium",
    icon: licIcon,
    to: "",
    isModal: true,
  },
  {
    title: "Credit Card Bill",
    icon: cardIcon,
    to: "",
    isModal: true,
  },
  {
    title: "Gas Bill",
    icon: gasIcon,
    to: "",
    isModal: true,
  },
  {
    title: "Broadband Bill",
    icon: bbIcon,
    to: "",
    isModal: true,
  },
  {
    title: "Water Bill",
    icon: waterIcon,
    to: "",
    isModal: true,
  },
  {
    title: "Landline Bill",
    icon: landIcon,
    to: "",
    isModal: true,
  },
  // {
  //   title: "Recharges",
  //   icon: Prepaid,
  //   to: "/customer/recharge",
  // },
];

export const travelServices = [
  {
    title: "Flight Booking",
    icon: flightBooking,
    to: "/customer/travel",
  },
  {
    title: "Hotel Booking",
    icon: hotelBooking,
    to: "/customer/travel",
  },
  {
    title: "Bus Booking",
    icon: busBooking,
    to: "/customer/travel",
  },
];

export const getNavImg = (title) => {
  let image;
  switch (title) {
    case "Electricity":
      image = Electricity;
      break;
    case "FASTag":
      image = FASTag;
      break;
    case "BBPS":
      image = BBPS;
      break;
    case "Gas Cylinder":
      image = Cylinder;
      break;
    case "Mobile Postpaid":
      image = Postpaid;
      break;
    case "Mobile Prepaid":
      image = Prepaid;
      break;
    case "Piped Gas":
      image = Gas;
      break;

    default:
      image = BBPS;
  }
  return image;
};

// export const customer_nav_super = [
//   {
//     title: "Dashboard",
//     icon: <HomeOutlinedIcon />,
//     to: "/customer/dashboard",
//   },
//   {
//     title: "Recharge/Bill",
//     icon: <MobileFriendlyOutlinedIcon />,
//     to: "/customer/recharge",
//   },
//   {
//     title: "BBPS",
//     icon: <FormatBoldIcon />,
//     to: "/customer/bbps",
//   },
//   {
//     title: "Money Transfer",
//     icon: <SwapHorizIcon />,
//     to: "/customer/money-transfer",
//   },
//   {
//     title: "Express Transfer",
//     icon: <SwipeRightOutlinedIcon />,
//     to: "/customer/express-transfer",
//   },
//   {
//     title: "Super Transfer",
//     icon: <BoltIcon />,
//     to: "/customer/super-transfer",
//   },
//   {
//     title: "UPI Transfer",
//     // icon: <SyncAltOutlinedIcon />,
//     icon: <QrCodeScannerIcon />,
//     to: "/customer/upi-transfer",
//   },
//   {
//     title: "AEPS",
//     icon: <TouchAppOutlinedIcon />,
//     to: "/customer/aeps",
//   },
//   {
//     title: "My Transactions",
//     icon: <ShuffleIcon />,
//     to: "/customer/transactions",
//   },
//   {
//     title: "Credit Request",
//     icon: <CurrencyRupeeOutlinedIcon />,
//     to: "/customer/cred-req",
//   },
//   {
//     title: "My Complaints",
//     icon: <SupportAgentIcon />,
//     to: "/customer/complaints",
//   },
//   {
//     title: "My Purchase",
//     icon: <FontAwesomeIcon icon={faLink} style={{ fontSize: "18px" }} />,
//     to: "/customer/purchase",
//   },
//   {
//     title: "Khata Book",
//     icon: <MenuBookOutlinedIcon />,
//     to: "/customer/khata-book",
//   },
// ];
export const account_nav = [
  {
    title: "Dashboard",
    icon: <HomeOutlinedIcon />,
    to: "/account/dashboard",
  },
];

//acc login
//user
//bank
//account
//txn

// export const customer_nav = [
//   {
//     title: "Dashboard",
//     icon: <HomeOutlinedIcon />,
//     to: "/customer/dashboard",
//   },
//   {
//     title: "Recharge/Bill",
//     icon: <MobileFriendlyOutlinedIcon />,
//     to: "/customer/recharge",
//   },
//   {
//     title: "BBPS",
//     icon: <FormatBoldIcon />,
//     to: "/customer/bbps",
//   },
//   {
//     title: "Money Transfer",
//     icon: <SwapHorizIcon />,
//     to: "/customer/money-transfer",
//   },
//   {
//     title: "Express Transfer",
//     icon: <SwipeRightOutlinedIcon />,
//     to: "/customer/express-transfer",
//   },
//   {
//     title: "Super Transfer",
//     icon: <BoltIcon />,
//     to: "/customer/super-transfer",
//   },
//   {
//     title: "Nepal Transfer",
//     icon: <OutlinedFlagIcon />,
//     to: "/customer/nepal-transfer",
//   },
//   {
//     title: "UPI Transfer",
//     icon: <QrCodeScannerIcon />,
//     to: "/customer/upi-transfer",
//   },
//   {
//     title: "AEPS",
//     icon: <TouchAppOutlinedIcon />,
//     to: "/customer/aeps",
//   },
//   {
//     title: "My Transactions",
//     icon: <ShuffleIcon />,
//     to: "/customer/transactions",
//   },
//   {
//     title: "Account Ledger",
//     icon: <EventNoteIcon />,
//     to: "/customer/account-ledger",
//   },
//   {
//     title: "Credit Request",
//     icon: <CurrencyRupeeOutlinedIcon />,
//     to: "/customer/cred-req",
//   },
//   {
//     title: "My Complaints",
//     icon: <SupportAgentIcon />,
//     to: "/customer/complaints",
//   },
//   {
//     title: "My Purchase",
//     icon: <FontAwesomeIcon icon={faLink} style={{ fontSize: "18px" }} />,
//     to: "/customer/purchase",
//   },
//   {
//     title: "Khata Book",
//     icon: <MenuBookOutlinedIcon />,
//     to: "/customer/khata-book",
//   },
// ];
