import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  Grid,
  TextField,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";
import { useState } from "react";
import WalletIcon from "@mui/icons-material/Wallet";
import Spinner from "../commons/Spinner";
import { postJsonData } from "../network/ApiController";
import ApiEndpoints from "../network/ApiEndPoints";
import { apiErrorToast } from "../utils/ToastUtil";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  boxShadow: 24,
  fontFamily: "Poppins",
  height: "max-content",
  overflowY: "scroll",
  p: 2,
};
const AddBalanceViaPG = ({ refresh }) => {
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let amt = document.getElementById("amount").value;
    const data = {
      amount: amt,
    };
    postJsonData(
      ApiEndpoints.CREATE_ORDER,
      data,
      setRequest,
      (res) => {
        const data = res.data.data;
        const hash = res.data.hash?.toUpperCase();
        const newData = {
          ...data,
          hash,
        };

        // prepare querystring parameters for the api .........
        const queryString = Object.keys(newData)
          .map(
            (key) =>
              `${encodeURIComponent(key)?.toUpperCase()}=${encodeURIComponent(
                newData[key]
              )}`
          )
          .join("&");
        const REDIRECT_URL = `https://www.paygoal.in/order/v1/payment?${queryString}`;
        window.open(REDIRECT_URL, "_self");
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };
  return (
    <>
      <div className="hover-zoom" onClick={handleOpen}>
        <IconButton
          className="hover-zoom"
          sx={{ display: "contents", color: "#fff", ml: 5 }}
        >
          <Tooltip title="Add Money" placement="left">
            <div className="d-flex">
              <WalletIcon className="hover-white" style={{ ml: "30px" }} />
              <Typography sx={{ fontWeight: "bold" }}>+</Typography>
            </div>
          </Tooltip>
        </IconButton>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="sm_modal">
          <Spinner loading={request} />
          <ModalHeader title="Add Balance" handleClose={handleClose} />
          <Box
            component="form"
            id="money_transfer"
            validate
            autoComplete="off"
            onSubmit={handleSubmit}
            sx={{
              "& .MuiTextField-root": { m: 2 },
            }}
          >
            <Grid container sx={{ pt: 1 }}>
              <Grid item md={12} xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    label="Amount"
                    id="amount"
                    size="small"
                    type="number"
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    InputProps={{
                      inputProps: {
                        max: 500000,
                        min: 100,
                      },
                    }}
                    required
                    onKeyDown={(e) => {
                      if (e.key === "+" || e.key === "-") {
                        e.preventDefault();
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <ModalFooter form="money_transfer" request={request} btn="Send" />
        </Box>
      </Modal>
    </>
  );
};
export default AddBalanceViaPG;
