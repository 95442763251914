import { Box, Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import {
  getActiveColor,
  getSecondaryColor,
  getTertiaryColor,
} from "../theme/setThemeColor";

const DashboardDataToggleComponent = ({ setGraphDuration }) => {
  const [isActive, setIsActive] = useState("TODAY");

  return (
    <Box
      sx={{
        display: "flex",
        "& .MuiButton-root": { mx: 0.5 },
      }}
    >
      <Button
        variant="outlined"
        sx={{
          background:
            isActive && isActive === "TODAY" ? getActiveColor() : "#fff",
          fontWeight: "bold",
          border: "1px solid" + getActiveColor(),
          color: isActive && isActive === "TODAY" ? "#fff" : getActiveColor(),
          "&:hover": {
            border: "1px solid" + getActiveColor(),
            color: "#fff",
            background: getActiveColor(),
          },
        }}
        onClick={() => {
          setIsActive("TODAY");
          setGraphDuration("TODAY");
        }}
      >
        Today
      </Button>
      <Button
        variant="outlined"
        color="warning"
        sx={{
          background:
            isActive && isActive === "THIS" ? getSecondaryColor() : "#fff",
          fontWeight: "bold",
          border: "1px solid" + getSecondaryColor(),
          color: isActive && isActive === "THIS" ? "#fff" : getSecondaryColor(),
          "&:hover": {
            border: "1px solid" + getSecondaryColor(),
            color: "#fff",
            background: getSecondaryColor(),
          },
        }}
        onClick={() => {
          setGraphDuration("THIS");
          setIsActive("THIS");
        }}
      >
        This
      </Button>
      <Button
        variant="outlined"
        sx={{
          // 00bf78
          background:
            isActive && isActive === "LAST" ? getTertiaryColor() : "#fff",
          fontWeight: "bold",
          border: "1px solid" + getTertiaryColor(),
          color: isActive && isActive === "LAST" ? "#fff" : getTertiaryColor(),
          "&:hover": {
            border: "1px solid" + getTertiaryColor(),
            color: "#fff",
            background: getTertiaryColor(),
          },
        }}
        onClick={() => {
          setGraphDuration("LAST");
          setIsActive("LAST");
        }}
      >
        Last
      </Button>
      {/* <ToggleButtonGroup
        orientation="horizontal"
        value={graphDuration}
        exclusive
        onChange={handleChange}
      >
        <ToggleButton
        value="TODAY"
        aria-label="list"
        className="custome-toggle payzoom-hover"
      >
        Today
      </ToggleButton>
        <ToggleButton
          value="THIS"
          aria-label="module"
          className="custome-toggle payzoom-hover"
        >
          This
        </ToggleButton>
        <ToggleButton
          value="LAST"
          aria-label="quilt"
          className="custome-toggle payzoom-hover"
        >
          Last
        </ToggleButton>
      </ToggleButtonGroup> */}
    </Box>
  );
};

export default DashboardDataToggleComponent;
