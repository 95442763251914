import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useContext } from "react";
import SignUp from "./View/SignUp";
import AuthContext from "./store/AuthContext";
import Dashboard from "./View/Dashboard";
import AdimUserView from "./View/AdimUserView";
import SideNav from "./component/SideNav";
import TransactionsView from "./View/AdminTransactionsView";
import CreditRequestView from "./View/AdminCreditRequestView";
import AdminAccountsView from "./View/AdminAccountsView";
import AdminBanksView from "./View/AdminBanksView";
import AdminAccountStatementView from "./View/AdminAccountStatementView";
import AdminBankStatementView from "./View/AdminBankStatementView";
import AdminOperatorView from "./View/AdminOperatorView";
import AdminRoutesView from "./View/AdminRoutesView";
import AdminPlanView from "./View/AdminPlanView";
import AdminMessageView from "./View/AdminMessageView";
import AdSaleView from "./View/AdSaleView";
import MyPurchaseView from "./View/MyPurchaseView";
import MyLedgerView from "./View/MyLedgerView";
import RechargeAndBillPay from "./View/RechargeAndBillPay";
import MoneyTransferView from "./View/MoneyTransferView";
import ExpressMoneyTransfer from "./View/ExpressMoneyTransfer";
import UPITransferView from "./View/UPITransferView";
import BBPSView from "./View/BBPSView";
import ComplaintsView from "./View/ComplaintsView";
import RetMyComplaints from "./View/RetMyComplaints";
import LoginPage from "./View/LoginPage";
import RetDdDashboard from "./View/RetDdDashboard";
import AccountDashboard from "./View/AccountDashboard";
import LandingContactUsPage from "./View/LandingContactUsPage";
import LandingPartnersPage from "./View/LandingPartnersPage";
import LandingAboutUsPage from "./View/LandingAboutUsPage";
import LandingServicesPage from "./View/LandingServicesPage";
import PageNotFound from "./component/PageNotFound";
import PrivacyPolicyView from "./View/PrivacyPolicyView";
import Terms from "./View/Terms";
import MainPage from "./View/MainPage";
import AdminNotificationsView from "./View/AdminNotificationsView";
import NewMyProfile from "./View/NewMyProfile";
import KhataBook from "./View/KhataBook";
import KhataBookStatement from "./View/KhataBookStatement";
import PaymentReceipt from "./component/PaymentReceipt";
import MiniStatementReceipt from "./component/MiniStatementReceipt";
import Unauthorized from "./component/Unauthorized";
import MoneyTransferReceipt from "./component/MoneyTransferReceipt";
import UnauthorizedPage from "./component/UnauthorizedPage";
import SuperMoneyTransfer from "./View/SuperMoneyTransfer";
import BcCerificate from "./View/BcCerificate";
import RefundPolicy from "./View/RefundPolicy";
import NepalTransfer from "./View/NepalTransfer";
import UserAccountLedger from "./View/UserAccountLedger";
import HorizontalSideNav from "./component/HorizontalSideNav";
import RetailerCertificate from "./View/RetailerCertificate";
import AEPS2View from "./View/aeps/AEPS2View";
// import { LandingPage1 } from "./View/landingpage/LandingPage1";
import LandingPage from "./View/LandingPage";
import DeactivateAccountPolicy from "./View/DeactivateAccountPolicy";
import RetDdTransactionView from "./View/RetDd/RetDdTransactionView";
import AsmTransactionsView from "./View/Asm/AsmTransactionsView";
import AdTransactionView from "./View/Ad/AdTransactionView";

function App() {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="" element={<MainPage />}>
            <Route path="/" element={<LandingPage />} />
            <Route path="our-services" element={<LandingServicesPage />} />
            <Route path="about-us" element={<LandingAboutUsPage />} />
            <Route path="our-partners" element={<LandingPartnersPage />} />
            <Route path="contact-us" element={<LandingContactUsPage />} />
          </Route>
          <Route path="/privacy-policy" element={<PrivacyPolicyView />} />
          <Route
            path="/deactivate-account-policy"
            element={<DeactivateAccountPolicy />}
          />
          {/*end*/}
          <Route path="/terms-conditions" element={<Terms />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/receipt" element={<PaymentReceipt />} />
          <Route path="/mt-receipt" element={<MoneyTransferReceipt />} />
          <Route path="/bank-statement" element={<MiniStatementReceipt />} />
          {/* unauthorized is un-used for now */}
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
          <Route path="*" element={<PageNotFound />} />

          {isLoggedIn && (
            <Route>
              {(authCtx?.user?.role === "Ret" ||
                authCtx?.user?.role === "Dd") && (
                <Route path="bc-certificate" element={<BcCerificate />} />
              )}
              {(authCtx?.user?.role === "Ret" ||
                authCtx?.user?.role === "Dd" ||
                authCtx?.user?.role === "Ad") && (
                <Route
                  path="retailer-certificate"
                  element={<RetailerCertificate />}
                />
              )}
              {/* 
              {(authCtx?.user?.role === "Ret" ||
                authCtx?.user?.role === "Dd") && (
                <Route
                  path="nepal-process"
                  element={<NepalTransferStaticPage />}
                />
              )} */}
              {/* admin Login routes */}
              {authCtx.user && authCtx.user.role === "Admin" && (
                <Route path="admin" element={<SideNav />}>
                  <Route path="dashboard" element={<Dashboard />} />
                  {/* <Route path="my-profile" element={<MyProfile />} /> */}
                  <Route path="my-profile" element={<NewMyProfile />} />
                  <Route path="users" element={<AdimUserView />} />
                  <Route path="transactions" element={<TransactionsView />} />
                  <Route path="accounts" element={<AdminAccountsView />} />
                  <Route path="banks" element={<AdminBanksView />} />
                  <Route path="messages" element={<AdminMessageView />} />
                  <Route
                    path="notification"
                    element={<AdminNotificationsView />}
                  />
                  <Route path="cred-req" element={<CreditRequestView />} />
                  <Route
                    path="accountStatement"
                    element={<AdminAccountStatementView />}
                  />
                  <Route
                    path="bankStatement"
                    element={<AdminBankStatementView />}
                  />
                  <Route path="operators" element={<AdminOperatorView />} />
                  <Route path="routes" element={<AdminRoutesView />} />
                  <Route path="plans" element={<AdminPlanView />} />
                  <Route path="complaints" element={<ComplaintsView />} />
                </Route>
              )}

              {/* asm login Routes */}
              {authCtx.user && authCtx.user.role === "Asm" && (
                <Route path="asm" element={<SideNav />}>
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="users" element={<AdimUserView />} />
                  <Route
                    path="transactions"
                    element={<AsmTransactionsView />}
                  />
                  <Route path="cred-req" element={<CreditRequestView />} />
                  <Route path="my-profile" element={<NewMyProfile />} />
                </Route>
              )}
              {authCtx.user && authCtx.user.role === "Api" && (
                <Route path="api-user" element={<SideNav />}>
                  <Route path="dashboard" element={<RetDdDashboard />} />
                  <Route path="transactions" element={<TransactionsView />} />
                  <Route path="cred-req" element={<CreditRequestView />} />
                  <Route path="my-profile" element={<NewMyProfile />} />
                </Route>
              )}
              {authCtx.user && authCtx.user.role === "Ad" && (
                <Route path="ad" element={<SideNav />}>
                  <Route path="dashboard" element={<RetDdDashboard />} />
                  <Route path="users" element={<AdimUserView />} />
                  <Route path="transactions" element={<AdTransactionView />} />
                  <Route path="cred-req" element={<CreditRequestView />} />
                  <Route path="sale" element={<AdSaleView />} />
                  <Route path="purchase" element={<MyPurchaseView />} />
                  <Route path="ledger" element={<MyLedgerView />} />
                  <Route path="my-profile" element={<NewMyProfile />} />
                  <Route path="khata-book" element={<KhataBook />} />
                  <Route
                    path="khata-statement"
                    element={<KhataBookStatement />}
                  />
                </Route>
              )}
              {authCtx.user &&
                (authCtx.user.role === "Ret" || authCtx.user.role === "Dd") &&
                authCtx.user.status === 1 && (
                  <Route path="customer" element={<SideNav />}>
                    {authCtx?.user?.layout * 1 === 2 && (
                      <Route path="services" element={<HorizontalSideNav />} />
                    )}
                    <Route path="dashboard" element={<RetDdDashboard />} />
                    <Route path="recharge" element={<RechargeAndBillPay />} />
                    <Route
                      path="money-transfer"
                      element={
                        // authCtx?.user?.dmt1 === 1 ? (
                        <MoneyTransferView />
                        // ) : (
                        //   <Unauthorized />
                        // )
                      }
                    />
                    <Route
                      path="express-transfer"
                      element={
                        authCtx?.user?.dmt4 === 1 ? (
                          <ExpressMoneyTransfer />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                    {authCtx?.user?.st === 1 && (
                      <Route
                        path="super-transfer"
                        element={<SuperMoneyTransfer />}
                      />
                    )}
                    {authCtx?.user?.nepal_transfer === 1 && (
                      <Route
                        path="nepal-transfer"
                        element={<NepalTransfer />}
                      />
                    )}
                    {authCtx?.user?.nepal_transfer === 1 && (
                      <Route
                        path="nepal-transfer/ekyc"
                        element={<NepalTransfer />}
                      />
                    )}
                    <Route
                      path="upi-transfer"
                      element={
                        authCtx?.user?.upi_transfer === 1 ? (
                          <UPITransferView />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                    <Route
                      path="bbps"
                      element={
                        authCtx?.user?.bbps === 1 ? (
                          <BBPSView />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    />
                    {/* <Route
                      path="aeps"
                      element={
                        authCtx?.user?.aeps === 1 ? (
                          <AEPSView />
                        ) : (
                          <Unauthorized />
                        )
                      }
                    /> */}
                    <Route path="aeps" element={<AEPS2View />} />
                    <Route
                      path="transactions"
                      element={<RetDdTransactionView />}
                    />
                    {authCtx?.user?.acst === 1 && (
                      <Route
                        path="account-ledger"
                        element={<UserAccountLedger />}
                      />
                    )}
                    <Route path="cred-req" element={<CreditRequestView />} />
                    <Route path="complaints" element={<RetMyComplaints />} />
                    <Route path="purchase" element={<MyPurchaseView />} />
                    <Route path="my-profile" element={<NewMyProfile />} />
                    <Route path="khata-book" element={<KhataBook />} />
                    <Route
                      path="khata-statement"
                      element={<KhataBookStatement />}
                    />
                  </Route>
                )}

              {authCtx.user && authCtx.user.role === "Acc" && (
                <Route path="account" element={<SideNav />}>
                  <Route path="dashboard" element={<AccountDashboard />} />{" "}
                </Route>
              )}
              {/* {authCtx.user && authCtx.user.role !== "Admin" && (
                  <Route path="other" element={<SideNav />}>
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="my-profile" element={<MyProfile />} />
                  </Route>
                )} */}
            </Route>
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
