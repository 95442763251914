import React, { useState } from "react";
import {
  Tooltip,
  IconButton,
  Snackbar,
  Grid,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Button,
  // Box,
  // TextField,
  // FormControl,
  // InputAdornment,
  // MenuItem,
} from "@mui/material";
import ApiEndpoints from "../network/ApiEndPoints";
import { datemonthYear } from "../utils/DateUtils";
import { CustomStyles } from "../component/CostomStyle";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import LaptopIcon from "@mui/icons-material/Laptop";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import CachedIcon from "@mui/icons-material/Cached";
import { get } from "../network/ApiController";
import { apiErrorToast } from "../utils/ToastUtil";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import CheckResponseModal from "../modals/CheckResponseModal";
import CheckStatusModal from "../modals/CheckStatusModal";
import ChangeStatusModal from "../modals/ChangeStatusModal";
import GetAdModalTxn from "../modals/GetAdModalTxn";
import useDebounce from "../utils/Debounce";
import { useEffect } from "react";
import { useContext } from "react";
import AuthContext from "../store/AuthContext";
import RaiseIssueModal from "../modals/RaiseIssueModal";
import { currencySetter } from "../utils/Currencyutil";
import ApiPaginateSearch from "../component/ApiPaginateSearch";
import PrintIcon from "@mui/icons-material/Print";
import ExcelUploadModal from "../modals/ExcelUploadModal";
import moment from "moment";
import { json2Csv, json2Excel } from "../utils/exportToExcel";
import { capitalize1 } from "../utils/TextUtil";
import FilterCard from "../modals/FilterCard";
import FilterModal from "../modals/FilterModal";
import {
  // columnOptions,
  nonAdminColOptions,
  searchOptions,
} from "../utils/constants";
import SearchIcon from "@mui/icons-material/Search";
import { useMemo } from "react";
import RightSidePannel from "../component/right_sidenav/RightSidePannel";

let refreshFilter;
let refresh;

let handleCloseModal;
const AdminTransactionsView = () => {
  const [apiData, setApiData] = useState([]);
  const [query, setQuery] = useState();
  const authCtx = useContext(AuthContext);
  const user = authCtx.user;
  const role = user?.role.toLowerCase();
  const [showOldTransaction, setShowOldTransaction] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  // console.log("selectedRows", selectedRows);
  const [search, setSearch] = useState("");
  const [request, setRequest] = useState();
  const [noOfResponses, setNoOfResponses] = useState(0);
  const [state, setState] = useState(false);
  const [rowData, setRowData] = useState({});

  // const [typeVal, setTypeVal] = useState("");
  const [asmList, setAsmList] = useState([]);

  const [typeList, setTypeList] = useState([]);

  const isFilterAllowed = useMemo(
    () =>
      user?.role.toLowerCase() === "admin" ||
      user?.role.toLowerCase() === "dd" ||
      user?.role.toLowerCase() === "ad" ||
      user?.role.toLowerCase() === "asm" ||
      user?.role.toLowerCase() === "ret" ||
      user?.role.toLowerCase() === "api",
    [user]
  );
  // const [columnOptions, setColumnOptions] = useState([]);

  const getExcel = () => {
    get(
      showOldTransaction && showOldTransaction
        ? ApiEndpoints.OLD_TRANSACTIONS
        : ApiEndpoints.GET_TRANSACTIONS,
      // ApiEndpoints.GET_USERS,
      `${
        query
          ? query + `&page=1&paginate=10&export=1`
          : `page=1&paginate=10&export=1`
      }`,
      setRequest,
      (res) => {
        const apiData = res.data.data;
        const newApiData = apiData.map((item) => {
          const created_at = moment(item.created_at).format("DD-MM-YYYY");
          const time_updated_at = moment(item.updated_at).format("LTS");
          return { ...item, created_at, time_updated_at };
        });
        json2Excel(
          `Transactions ${moment(new Date().toJSON()).format(
            "Do MMM YYYY"
          )} | ${moment(new Date().toJSON()).format("hh:mm a")}`,
          JSON.parse(JSON.stringify(newApiData && newApiData))
        );
        handleCloseModal();
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  const getCsv = () => {
    get(
      showOldTransaction && showOldTransaction
        ? ApiEndpoints.OLD_TRANSACTIONS
        : ApiEndpoints.GET_TRANSACTIONS,
      `${
        query
          ? query + `&page=1&paginate=10&export=1`
          : `page=1&paginate=10&export=1`
      }`,
      setRequest,
      (res) => {
        const apiData = res.data.data;
        const newApiData = apiData.map((item) => {
          const created_at = moment(item.created_at).format("DD-MM-YYYY");
          const time_updated_at = moment(item.updated_at).format("LTS");
          return { ...item, created_at, time_updated_at };
        });
        json2Csv(
          `Transactions ${moment(new Date().toJSON()).format(
            "Do MMM YYYY"
          )} | ${moment(new Date().toJSON()).format("hh:mm a")}`,
          JSON.parse(JSON.stringify(newApiData && newApiData))
        );
        handleCloseModal();
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };
  const getAsmValue = () => {
    get(
      ApiEndpoints.GET_USERS,
      `page=1&paginate=10&role=Asm&export=`,
      "",
      (res) => {
        const asmArray = res.data.data;
        setAsmList(
          asmArray &&
            asmArray.map((item) => {
              return {
                id: item.id,
                name: item.name,
              };
            })
        );
      },
      (error) => {
        apiErrorToast(error);
      }
    );
  };

  // get types
  const getTypes = () => {
    if (typeList.length === 0) {
      get(
        ApiEndpoints.GET_CATEGORIES,
        "",
        setRequest,
        (res) => {
          const data = res.data.data;

          setTypeList(data);
        },
        (err) => {
          apiErrorToast(err);
        }
      );
    }
  };

  const [isShowFilterCard, setIsShowFilterCard] = useState(false);

  useEffect(() => {
    getTypes();
    if (role === "admin") {
      getAsmValue();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function refreshFunc(setQueryParams) {
    if (refresh) refresh();
    // if (refreshFilter) refreshFilter();
  }
  const SearchEst = useDebounce(search, 800);
  useEffect(() => {
    setQuery(`establishment=${SearchEst}`);
  }, [SearchEst]);

  const [operatorList, setOperatorList] = useState([]);
  const getOperatorVal = () => {
    get(
      ApiEndpoints.GET_OPERATOR,
      "",
      "",
      (res) => {
        const opArray = res.data.data;
        // const op = opArray.map((item) => {
        //   return {
        //     code: item.code,
        //     name: item.name,
        //   };
        // });
        setOperatorList(opArray);
      },
      (error) => {
        apiErrorToast(error);
      }
    );
  };

  const [open, setOpen] = React.useState(false);
  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const copyToClipBoard = (copyMe) => {
    try {
      navigator.clipboard.writeText(copyMe);
    } catch (err) {}
  };

  // table data & conditional styles.....
  const conditionalRowStyles = [
    {
      when: (row) => row.operator.toLowerCase() === "admin transfer",
      style: {
        backgroundColor: "#dc5f5f38",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];
  ////  disable rows
  const isStatusPending = (row) =>
    // row.status.toLowerCase() === "pending" ||
    row.status.toLowerCase() === "refund" ||
    row.status.toLowerCase() === "failed";

  const columns = [
    {
      name: "Created/Updated",
      selector: (row) => (
        <div style={{ textAlign: "left" }}>
          <div style={{ marginBottom: "5px" }}>
            {datemonthYear(row.created_at)}
          </div>

          <div>{datemonthYear(row.updated_at)}</div>
        </div>
      ),
      wrap: true,
      grow: 1.3,
    },
    {
      name: "Route",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <div>
            {row.platform === "APP" ? (
              <Tooltip title="APP">
                <InstallMobileIcon fontSize="small" />
              </Tooltip>
            ) : row.platform === "WEB" ? (
              <Tooltip title="WEB">
                <LaptopIcon fontSize="small" />
              </Tooltip>
            ) : row.platform === "ANDROID" ? (
              <Tooltip title="ANDROID">
                <AndroidIcon fontSize="small" />
              </Tooltip>
            ) : row.platform === "IOS" ? (
              <Tooltip title="IOS">
                <AppleIcon fontSize="small" />
              </Tooltip>
            ) : (
              <Tooltip title="API">
                <SyncAltIcon fontSize="small" />
              </Tooltip>
            )}
          </div>
          <div className="fw-bold">{row.route}</div>
        </div>
      ),
      center: false,
      omit: user && user.role === "Admin" ? false : true,
      width: "70px",
    },
    {
      name: <span className="">Order ID</span>,
      selector: (row) => (
        <div className="d-flex flex-column align-items-start">
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              // color: "#566573",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              copyToClipBoard(row.order_id);
              handleClickSnack();
            }}
          >
            {row.order_id}
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleCloseSnack}
              message="number copied"
              sx={{ zIndex: 10000 }}
            />
          </Typography>
          {user && user.role === "Admin" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <CheckStatusModal row={row} />
              <CheckResponseModal row={row} />
            </div>
          ) : (
            ""
          )}
        </div>
      ),

      width: "180px",
    },
    // {
    //   name: <span className="">Client ID</span>,
    //   selector: (row) => (
    //     <div className="d-flex flex-column align-items-start">
    //       <Typography
    //         sx={{
    //           fontSize: "12px",
    //           fontWeight: "600",
    //           // color: "#566573",
    //           "&:hover": {
    //             cursor: "pointer",
    //           },
    //         }}
    //         onClick={() => {
    //           copyToClipBoard(row.client_id);
    //           handleClickSnack();
    //         }}
    //       >
    //         {row.client_id}
    //         <Snackbar
    //           open={open}
    //           autoHideDuration={3000}
    //           onClose={handleCloseSnack}
    //           message="number copied"
    //           sx={{ zIndex: 10000 }}
    //         />
    //       </Typography>
    //     </div>
    //   ),
    //   omit:
    //    ||
    //     (user?.role !== "Admin" && user?.role !== "Api"),
    //   width: "180px",
    // },
    {
      name: <span className="">Operator</span>,
      selector: (row) => (
        <div>
          <Tooltip
            placement="right"
            title={
              row.operator === "Vendor Payments"
                ? "Express Transfer"
                : row.operator
            }
          >
            <div className="break-words mr-2">
              {row.operator === "Vendor Payments"
                ? "Express Transfer"
                : row.operator}
            </div>
          </Tooltip>
          {(user?.role === "Admin" || user?.role === "Api") && (
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                // color: "#566573",
                textAlign: "left",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                copyToClipBoard(row.client_id);
                handleClickSnack();
              }}
            >
              {row.client_id}
              <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleCloseSnack}
                message="number copied"
                sx={{ zIndex: 10000 }}
              />
            </Typography>
          )}
        </div>
      ),
      wrap: true,
      width: "150px",
    },
    // est missing from ad login
    {
      name: <span className="">EST</span>,
      selector: (row) => (
        <div
          style={{
            textAlign: "left",
          }}
        >
          <Tooltip title={capitalize1(row.establishment)}>
            <div className="d-flex" style={{ textAlign: "left" }}>
              <span className="break-words" style={{ marginRight: "4px" }}>
                {capitalize1(row.establishment)}
              </span>
              {user && user.role === "Admin" ? <GetAdModalTxn row={row} /> : ""}
            </div>
          </Tooltip>
        </div>
      ),
      omit:
        user?.role !== "Dd" && user?.role !== "Ret" && user?.role !== "Api"
          ? false
          : true,
      // omit: user && user.role === "Ret" ? true : false,
      wrap: true,
      width: "180px",
      center: false,
    },
    {
      name: <span className="">Number</span>,
      selector: (row) => (
        <div className="d-flex flex-column align-items-start">
          <Typography
            sx={{
              fontSize: "inherit",
              fontWeight: "600",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              copyToClipBoard(row.number);
              handleClickSnack();
            }}
          >
            {row.number}
          </Typography>
        </div>
      ),
      wrap: true,
      center: false,
      width: "100px",
    },
    {
      name: <span className="ms-3">Info</span>,
      selector: (row) => (
        <Typography
          className="d-flex flex-column align-items-start ms-3"
          sx={{
            fontSize: "9px",
            textAlign: "left",
            fontWeight: "600",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => {
            if (row.ben_acc) copyToClipBoard(row.ben_acc);
            handleClickSnack();
          }}
        >
          <div>{row?.ben_name}</div>
          <div>{row?.ben_acc ? row?.ben_acc : row?.number}</div>
          <div>{row.ifsc ? row?.ifsc : row?.establishment}</div>
        </Typography>
      ),

      wrap: true,
      width: "120px",
    },
    {
      name: "Comm/TDS",
      selector: (row) => (
        <>
          <div>+{Number(row.ad_comm).toFixed(2)}</div>
          <div style={{ color: "red " }}>-{Number(row.ad_tds).toFixed(2)}</div>
        </>
      ),
      omit: user && user.role !== "Ad",
      // width: "80px",
    },
    {
      name: <span className="pe-2">Amount</span>,
      cell: (row) => (
        <div className="d-flex flex-column align-items-end pe-3 fw-bold">
          <div>{currencySetter(row.amount)}</div>
          <div
            style={{
              color: row.txn_type === "CR" ? "green" : "red",
              textAlign: "right",
            }}
          >
            {row.txn_type === "CR" ? "+ " : "- "}
            {currencySetter(row.net_amount)}
          </div>
        </div>
      ),
      right: true,
    },
    {
      name: "Closing",
      selector: (row) => (
        <div className="d-flex align-items-start flex-column fw-bold">
          <div>{currencySetter(row.w1)}</div>
          <div style={{ color: "#F29132" }}>{currencySetter(row.w2)}</div>
          {user.role === "Admin" && (
            <div style={{ color: "#E87204" }}>
              {currencySetter(row.ad_closing)}
            </div>
          )}
        </div>
      ),
    },
    {
      name: "Adm Comm.",
      selector: (row) => (
        <div className="d-flex align-items-start flex-column">
          <div>{currencySetter(row.a_comm)}</div>
        </div>
      ),
      omit: user && user.role !== "Admin",
      width: "100px",
    },

    {
      name: "Status",
      selector: (row) => (
        <>
          <div
            className="px-2 text-uppercase"
            style={{
              color: "#fff",
              backgroundColor:
                row.status && row.status === "SUCCESS"
                  ? "#00BF78"
                  : row.status && row.status === "PENDING"
                  ? "#F08D17"
                  : row.status && row.status === "REFUND"
                  ? "#E87204"
                  : row.status && row.status === "FAILED"
                  ? "#DC6F6F"
                  : "#00BF78",
              fontWeight: "bold",
              borderRadius: "4px",
              minWidth: "85px",
            }}
          >
            {row.status && row.status === "SUCCESS"
              ? "Success"
              : row.status && row.status === "PENDING"
              ? "Pending"
              : row.status && row.status === "REFUND"
              ? "Refund"
              : row.status && row.status === "FAILED"
              ? "Failed"
              : "Success"}
          </div>
          <div
            className="break-words"
            style={{
              fontSize: "9px",
              maxWidth: "85px",
              marginTop: "5px",
              color: "#000",
              fontWeight: "600",
            }}
          >
            {row.op_id}
          </div>
        </>
      ),
      wrap: true,

      width: "120px",
    },
    {
      name: "Action",
      selector: (row) => <ChangeStatusModal row={row} refresh={refresh} />,
      wrap: true,
      center: false,
      omit: user && user.role === "Admin" ? false : true,
      // omit: user && user.role === "Admin" ? false : true,
    },
    {
      name: "Raise Issue",
      selector: (row) =>
        row.status &&
        (row.status === "SUCCESS" || row.status === "PENDING") && (
          <RaiseIssueModal row={row} refresh={refresh} />
        ),
      wrap: true,
      omit: user && (user.role === "Ret" || user.role === "Dd") ? false : true,
    },
    {
      name: "Details",
      selector: (row) => (
        <Button
          type="text"
          onClick={() => {
            setRowData(row);
            setState(true);
          }}
          sx={{
            textTransform: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          className="just-hover"
        >
          View
        </Button>
      ),
    },
  ];

  // const searchOptions = [
  //   { field: "Number", parameter: "number" },
  //   { field: "Account", parameter: "ben_acc" },
  //   { field: "Username", parameter: "username" },
  // ];
  const statusList = [
    { name: "SUCCESS", code: "SUCCESS" },
    { name: "PENDING", code: "PENDING" },
    { name: "REFUND", code: "REFUND" },
    { name: "FAILED", code: "FAILED" },
  ];
  useEffect(() => {
    if (selectedRows) {
      localStorage.setItem(
        "selectedRow",
        JSON.stringify(selectedRows.selectedRows)
      );
    }
    return () => {};
  }, [selectedRows]);

  // // use effect for dynamic filter values
  // useEffect(() => {
  //   if (apiData.length > 0) {
  //     const {
  //       route: Route,
  //       order_id: OrderId,
  //       operator: Operator,
  //       establishment: EST,
  //       number: Number,
  //       info: Info,
  //       amount: Amount,
  //       w1: Closing,
  //       status: Status,
  //     } = apiData[0];

  //     const filterKeysObj = {
  //       Route,
  //       OrderId,
  //       Operator,
  //       EST,
  //       Number,
  //       Info,
  //       Amount,
  //       Closing,
  //       Status,
  //     };
  //     const value = Object.keys(filterKeysObj);

  //     setColumnOptions([...value, "Action"]);
  //   }
  // }, [apiData]);

  return (
    <Grid container xs={12}>
      {/* user && user.role.toLowerCase() === "admin" */}
      <Grid lg={12} sm={12} xs={12}>
        <ApiPaginateSearch
          actionButtons={
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { md: "end", xs: "start" },
                alignItems: "center",
                flexDirection: { md: "row", xs: "column" },
                pr: 5,
              }}
            >
              <FormGroup>
                <FormControlLabel
                  sx={{
                    mt: { md: 0, sm: 2, xs: 2 },
                    mb: { md: 0, sm: 2, xs: 2 },
                  }}
                  control={
                    <Switch
                      value={showOldTransaction}
                      defaultChecked={showOldTransaction}
                      onChange={() =>
                        setShowOldTransaction(!showOldTransaction)
                      }
                    />
                  }
                  label={
                    <Typography variant="body2" style={{ fontSize: "15px" }}>
                      Old
                    </Typography>
                  }
                />
              </FormGroup>
              {user?.role?.toLowerCase() !== "admin" &&
                user?.role?.toLowerCase() !== "asm" &&
                user?.role?.toLowerCase() !== "ad" && (
                  <Tooltip title="Download Receipt" placement="top">
                    <PrintIcon
                      color="success"
                      className=" mx-2 refresh-purple"
                      onClick={() => {
                        if (
                          selectedRows &&
                          selectedRows.selectedRows &&
                          selectedRows.selectedRows.length > 0
                        ) {
                          window.open("/receipt", "_blank");
                        } else {
                          apiErrorToast("No Transaction Selected.");
                        }
                      }}
                    />
                  </Tooltip>
                )}
              <div className="mx-2">
                <ExcelUploadModal
                  twobuttons="Download Csv"
                  btn
                  request={request}
                  getExcel={getExcel}
                  getCsv={getCsv}
                  noOfResponses={noOfResponses}
                  setQuery={setQuery}
                  handleCloseCB={(closeModal) => {
                    handleCloseModal = closeModal;
                  }}
                />
              </div>

              <Tooltip title="refresh">
                <IconButton
                  aria-label="refresh"
                  color="success"
                  onClick={() => {
                    refreshFunc(setQuery);
                  }}
                >
                  <CachedIcon className="refresh-purple" />
                </IconButton>
              </Tooltip>
              <span className="filter-sm">
                <FilterModal
                  ifdateFilter
                  ifrouteFilter={user && user.role.toLowerCase() === "admin"}
                  ifoperatorFilter
                  ifstatusFilter
                  iforderidFilter
                  ifestFilter={
                    user &&
                    user.role.toLowerCase() !== "ret" &&
                    user &&
                    user.role.toLowerCase() !== "dd"
                  }
                  ifnumberFilter
                  ifotherFilter
                  // getRouteVal={getRouteVal}
                  // routeList={routeList}
                  // setRouteList={setRouteList}
                  operatorList={operatorList}
                  statusList={statusList}
                  getOperatorVal={getOperatorVal}
                  setQuery={setQuery}
                  query={query}
                  clearHookCb={(cb) => {
                    refreshFilter = cb;
                  }}
                  refresh={refresh}
                  isShowFilterCard={isShowFilterCard}
                  setIsShowFilterCard={setIsShowFilterCard}
                />
              </span>
            </Grid>
          }
          apiEnd={
            showOldTransaction && showOldTransaction
              ? ApiEndpoints.OLD_TRANSACTIONS
              : ApiEndpoints.GET_TRANSACTIONS
          }
          searchOptions={searchOptions[`${role}`]}
          setQuery={setQuery}
          columns={columns}
          apiData={apiData}
          setApiData={setApiData}
          tableStyle={CustomStyles}
          queryParam={query ? query : ""}
          returnRefetch={(ref) => {
            refresh = ref;
          }}
          conditionalRowStyles={conditionalRowStyles}
          selectableRows={
            user &&
            user.role !== "Admin" &&
            user.role !== "Asm" &&
            user.role !== "Ad"
            // selectedRows?.selectedRows.length === 1 &&
            // selectedRows?.selectedRows[0].operator === "Super Transfer"
          }
          selectableRowDisabled={isStatusPending}
          onSelectedRowsChange={(data) => {
            setSelectedRows(data);
          }}
          responses={(val) => {
            setNoOfResponses(val);
          }}
          filterComponent={
            <FilterCard
              showSearch={false}
              ifdateFilter
              ifrouteFilter
              ifoperatorFilter
              ifstatusFilter
              iforderidFilter
              // type and category is same
              ifTypeFilter
              ifUsernameFilter
              ifestFilter
              ifnumberFilter
              ifotherFilter
              //
              ifAdIdFilter
              ifAsmFilter
              asmList={asmList}
              typeList={typeList.filter((item) => item.name !== "ALL")}
              ifClientIdFilter
              nonAdminColOptions={nonAdminColOptions[`${role}`]}
              statusList={statusList}
              operatorList={operatorList}
              getOperatorVal={getOperatorVal}
              setQuery={setQuery}
              query={query}
              clearHookCb={(cb) => {
                refreshFilter = cb;
              }}
              getTypes={getTypes}
              refresh={refresh}
              isShowFilterCard={isShowFilterCard}
              setIsShowFilterCard={setIsShowFilterCard}
            />
          }
          isFilterAllowed={isFilterAllowed}
        />
      </Grid>
      <RightSidePannel
        state={state}
        setState={setState}
        row={rowData}
        setRow={setRowData}
        buttons={<ChangeStatusModal row={rowData} refresh={refresh} />}
      />
    </Grid>
  );
};

export default AdminTransactionsView;
