import {
  Box,
  Button,
  FormControl,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import PaymentsIcon from "@mui/icons-material/Payments";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ModalHeader from "./ModalHeader";
import PinInput from "react-pin-input";
import { postJsonData } from "../network/ApiController";
import ApiEndpoints from "../network/ApiEndPoints";
import { apiErrorToast } from "../utils/ToastUtil";
import ModalFooter from "./ModalFooter";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  boxShadow: 24,

  height: "max-content",
  overflowY: "scroll",
  p: 2,
};

const NepalMtModal = ({ receiver, nepalAllRes, reqNo, token }) => {
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState(false);
  const [otp, setOtp] = useState();

  // console.log("receiver", receiver);
  const { paymentMode } = receiver;
  const { customer } = nepalAllRes;
  // console.log("customer", customer);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    let data = {};
    data = {
      SenderName: customer?.Name,
      SenderGender: customer?.Gender,
      SenderDoB: customer.Dob,
      SenderAddress: customer.Address,
      SenderMobile:
        typeof customer?.Mobile?.string === "string"
          ? customer?.Mobile?.string
          : customer?.Mobile?.string[0],
      SenderCity: customer.City,
      SenderDistrict: customer.District,
      SenderState: customer.State,
      SenderNationality: customer.Nationality,
      Employer: customer.Employer,
      SenderIDType: customer.Ids.IdIdType,
      SenderIDNumber: customer.Ids.IdNumber,
      IncomeSource: customer.IncomeSource,
      Relationship: receiver.Relationship,
      ReceiverID: receiver.ReceiverId,
      ReceiverName: receiver.Name,
      ReceiverGender: receiver.Gender,
      ReceiverAddress: receiver.Address,
      ReceiverMobile: receiver.Mobile,
      // ReceiverCity: receiver.ReceiverId,
      PaymentMode: receiver.PaymentMode,
      // CollectedAmount: "",
      // ServiceCharge: "",
      // SendAmount: "",
      // CustomerId:customer.CustomerId,
      // req_id: reqNo,
      // token,
      // PartnerPinNo
      // RemittanceReason
      // CSPCode
      // OTPProcessId
      OTP: otp,
    };
    e.preventDefault();
    postJsonData(
      ApiEndpoints.NEPAL_SEND_TRANSACTION,
      data,
      setRequest,
      (res) => {
        console.log("res, ", res.data);
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end",
      }}
    >
      <Button
        className="btn-background-add-bene"
        sx={{
          textTransform: "capitalize",
          fontSize: "12px",
          minWidth: "110px",
        }}
        startIcon={
          paymentMode === "Cash Payment" ? (
            <PaymentsIcon />
          ) : (
            <AccountBalanceIcon />
          )
        }
        onClick={handleOpen}
      >
        {paymentMode === "Cash Payment" ? "Cash" : "Account"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="sm_modal">
          <ModalHeader title="Send Money" handleClose={handleClose} />
          <Box
            component="form"
            id="sendTransaction"
            validate
            autoComplete="off"
            onSubmit={handleSubmit}
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
          >
            <Grid item md={12} xs={12}>
              <FormControl sx={{ width: "100%" }}>
                <TextField label="Amount" id="amt" size="small" required />
              </FormControl>
            </Grid>
            <Grid
              md={12}
              xs={12}
              sx={{ display: "flex", justifyContent: "center", mt: 2 }}
            >
              <FormControl>
                <Typography
                  sx={{ display: "flex", justifyContent: "center", mb: 1 }}
                >
                  Enter OTP
                </Typography>
                <PinInput
                  length={6}
                  focus
                  type="password"
                  onChange={(value, index) => {
                    setOtp(value);
                  }}
                  regexCriteria={/^[0-9]*$/}
                />
              </FormControl>
            </Grid>
          </Box>
          <ModalFooter form="sendTransaction" type="submit" request={request} />
        </Box>
      </Modal>
    </Box>
  );
};

export default NepalMtModal;
