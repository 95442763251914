/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { postJsonData } from "../network/ApiController";
import { eventListener, isNotPending } from "../utils/ApiHelper";
import { jsonUpload } from "../utils/JsonUpload";
import { okSuccessToast } from "../utils/ToastUtil";
import { readFileToJsonPromise } from "../utils/FileUtil";
import Spinner from "../commons/Spinner";
import { Icon } from "@iconify/react";
import { jsonToExcel } from "../utils/exportToExcel";
import { CustomStyles } from "./CostomStyle";
import ModalFooter from "../modals/ModalFooter";
import Mount from "./Mount";
import { getActiveColor } from "../theme/setThemeColor";

// ----------------------------------------------------------------------
const dummyCol1 = [
  {
    number: "your data will come here",
    status: "order status will come here",
  },
];
let ecolumns = [];

export default function BulkUpload({
  refresh,
  dummyCol = dummyCol1,
  headers,
  type = "upload",
  apiEnd = "",
  bulkApiColums,
  isPost = true,
  isSampleDownload = true,
  excelByApi = false,
  typeData,
  isRecursive = true,
  title,
  additionalInfo,
}) {
  const [open, setOpen] = useState(false);
  const [excelFile, setExcelFile] = useState(null);
  const [data, setData] = useState([]);
  const [apiResponse, setApiResponse] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [failedCount, setFailedCount] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [successCount, setSuccessCount] = useState(0);
  const [request, setRequest] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSuccessCount(0);
    setFailedCount(0);
    setData([]);
    setApiResponse([]);
    setIsComplete(false);
  };
  ecolumns = [
    {
      name: "S.No",
      cell: (row) => row.sno,
      sortable: true,
      width: "60px",
      center: true,
    },
  ];

  if (type !== "upload") {
    ecolumns = bulkApiColums;
  }

  useEffect(() => {
    if (apiResponse?.length > 0 && isRecursive) {
      setSuccessCount(
        apiResponse?.length > 0 &&
          apiResponse.filter((item) =>
            item?.meta?.api_status?.startsWith("SUCCESS")
          ).length
      );
      setFailedCount(
        apiResponse?.length > 0 &&
          apiResponse.filter((item) =>
            item?.meta?.api_status?.startsWith("FAILED")
          ).length
      );
    } else {
      if (apiResponse.length > 0) {
        setSuccessCount((prev) => data.length - apiResponse?.length);
        setFailedCount((prev) => apiResponse?.length);
      } else {
        setSuccessCount((prev) => data.length);
        setFailedCount((prev) => 0);
      }
    }
    return () => {};
  }, [apiResponse]);

  const createData = (data) => {
    const data1 = data.map((item) => ({ ...item }));
    setRequest(true);
    setTimeout(() => {
      eventListener(() => {
        setRequest(false);
        setData([...data]);
      });
    }, 200);

    setFinalData(data1);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const np = isNotPending();
      if (data && ((type === "upload" && np) || type !== "upload")) {
        const mapData = finalData.length > 0 ? finalData : data;
        const apiPayload = finalData.length > 0 ? finalData : data;
        // eslint-disable-next-line no-unused-vars
        const tempData = mapData.map((item) => ({
          data: item,
          meta: {
            processing: false,
          },
        }));

        jsonUpload(
          apiPayload,
          setRequest,
          (result) => {
            // console.log("data.l=<", data.length);
            // setSuccessCount((prev) => data.length);
            // setFailedCount((prev) => 0);
            setApiResponse(result);
            okSuccessToast("Update Complete");
            if (refresh) refresh();
            setIsComplete(true);
          },
          (error) => {
            setIsComplete(true);
            if (refresh) refresh();
            setApiResponse(error.response.data.data);
          },
          apiEnd,
          isPost
        );
      } else {
        // createData(data);
      }
    }
  };

  useEffect(() => {
    // console.log('effect');
    if (excelFile) {
      // console.log('effect excelFile=>', excelFile);
      setRequest(true);
      const reader = new FileReader();
      // console.log('reader=>', reader);
      reader.onload = function (e) {
        Promise.resolve(readFileToJsonPromise(e, setData))
          .then(
            (result) => {
              // console.log('data=>', data);
              // console.log('Progress finished=>', result);
              setData(result);
              setRequest(false);
              createData(result);
            },
            () => {
              // console.log('Progress finished error=>');
              setRequest(false);
            }
          )
          .catch(console.log);
      };
      reader.readAsArrayBuffer(excelFile);
    }
    return () => {};
  }, [excelFile]);

  return (
    <div>
      <Button className="button-purple" onClick={handleClickOpen}>
        Bulk Upload
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="bulk-vendor-dialog-title"
      >
        <DialogTitle>{title}</DialogTitle>

        <Box
          component="form"
          id="bulk-upload"
          noValidate={false}
          autoComplete="off"
          onSubmit={handleSubmit}
          sx={{
            p: 3,
            position: "relative",
          }}
        >
          <Spinner loading={request} />
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              background: "#d3d3d3",
              pb: 2,
            }}
          >
            {!isComplete && (
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12} md={8}>
                  <input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    id="excel_file"
                    name="excel_file"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      setExcelFile(file);
                    }}
                  />
                </Grid>
                {isSampleDownload && (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                      textAlign: { xs: "left", md: "right" },
                    }}
                  >
                    <Button
                      variant="text"
                      onClick={() => {
                        jsonToExcel(
                          `${type} (strictly follow this structure)`,
                          bulkApiColums,
                          headers
                        );
                      }}
                    >
                      <Icon icon="typcn:download" />
                      Download Sample
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
            {isComplete && (
              <>
                <Grid container sx={{ mt: { xs: 2, sm: 0 }, pl: 4 }}>
                  <Grid item xs={12} sm={4}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Box sx={{ fontSize: "14px" }}>Total</Box>
                        <h4>{data?.length}</h4>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <ApiResponseComponent
                      title="success"
                      count={successCount}
                      isDownload={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <ApiResponseComponent
                      title="failed"
                      count={failedCount}
                      apiResponse={apiResponse}
                      headers={headers}
                      isRecursive={isRecursive}
                      isDownload={failedCount > 0}
                    />
                  </Grid>
                  {type === "courier_order_id" && (
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          mt: 2,
                        }}
                      >
                        Your Order IDs File is ready to download:
                      </Typography>
                      <Button
                        className="secondary-button"
                        sx={{
                          py: 0,
                        }}
                      >
                        Download Now
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
            <Box sx={{ mt: 2 }}>
              {data && data.length > 0 && apiResponse.length === 0 && (
                <Grid item xs={12}>
                  <DataTable
                    columns={ecolumns}
                    data={data && data}
                    customStyles={CustomStyles}
                    pagination
                    paginationServer={false}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        </Box>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancel
          </Button>
          <Mount visible={!isComplete}>
            <ModalFooter
              text={"Submit"}
              disabled={data.length === 0 || request}
              type={"submit"}
              form={"bulk-upload"}
              red
              loading
            />
          </Mount>
        </DialogActions>

        {/* </DialogContent> */}
      </Dialog>
    </div>
  );
}

const ApiResponseComponent = ({
  title = "",
  count,
  apiResponse,
  headers,
  isRecursive,
  isDownload = true,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "end",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              color: "#000",
            }}
          >
            {title}
          </Box>
          <div className="d-flex align-items-start flex-column justify-content-start">
            <h4
              style={{
                color: "#000",
              }}
            >
              {count}
            </h4>
            <Mount visible={isDownload}>
              <Tooltip title="Download Failed list" placement="top">
                <Button
                  className="secondary-button"
                  sx={{
                    py: 0,
                  }}
                  onClick={() => {
                    if (isRecursive)
                      jsonToExcel(
                        `${title}`,
                        apiResponse?.length > 0 &&
                          apiResponse
                            .filter((item) => item?.meta?.error)
                            .map((item) => {
                              item.data.error = item.meta?.error;
                              return item.data;
                            }),
                        headers
                      );
                    else {
                      console.log("apiResponse=>", apiResponse);
                      jsonToExcel(`${title}_uploads`, apiResponse, headers);
                    }
                  }}
                >
                  Download
                </Button>
              </Tooltip>
            </Mount>
          </div>
        </Box>
      </Box>
    </>
  );
};
