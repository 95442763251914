import React, { useContext, createContext, useState } from "react";
import ApiEndpoints, { BASE_URL } from "../network/ApiEndPoints";
import { get } from "../network/ApiController";
import { apiErrorToast, errorNotiToast } from "../utils/ToastUtil";
import AuthContext from "./AuthContext";
import { useEffect } from "react";
import { useCallback } from "react";
import axios from "axios";
import { AEPS_TYPE } from "../utils/constants";
import { getRemainingTime } from "../View/aeps/AEPSTimer";

const CommonContext = createContext();
const MINUTE_MS = 120000;

export const CommonContextProvider = ({ children }) => {
  const [recentData, setRecentData] = useState([]);
  const [recentLoading, setRecentLoading] = useState(false);
  // find which section we are on ....
  const [section, setSection] = useState("");
  const [openNoti, setOpenNoti] = useState(false);
  const [pushFlag, setPushFlag] = useState(true);
  const [notiCount, setNotiCount] = useState(0);
  const [userRequest, setUserRequest] = useState(false);
  const authCtx = useContext(AuthContext);
  const saveUser = authCtx.saveUser;
  const setDocsInLocal = authCtx.setDocsInLocal;
  const setLocation = authCtx.setLocation;
  const [aepsType, setAepsType] = useState();
  const isLoggedIn = authCtx.isLoggedIn;

  // AEPS TIMER hooks
  const [openAeps2FAModal, setOpenAeps2FAModal] = useState();
  // console.log("openAeps2FAModal", openAeps2FAModal);
  const [checkIf2FaCalled, setCheckIf2FaCalled] = useState("notdone");
  const [timeInSec, setTimeInSec] = useState(null);

  const testRedToast = (message) => {
    errorNotiToast
      .fire({
        title: message,
      })
      .then((resVal) => {
        if (resVal?.isConfirmed) {
          setOpenNoti(true);
        }
      });
  };

  const getRecentData = () => {
    get(
      ApiEndpoints.GET_RECENT_DATA,
      "",
      setRecentLoading,
      (res) => {
        if (res && res) setRecentData(res.data.data);
        else setRecentData();
      },
      (error) => {
        apiErrorToast(error);
      }
    );
  };

  const refreshUser = useCallback(() => {
    get(
      ApiEndpoints.GET_ME_USER,
      "",
      setUserRequest,
      (res) => {
        const data = res.data.data;
        const docs = res.data.docs;
        setNotiCount(res.data.notification);
        saveUser(data);
        if (docs && typeof docs === "object") {
          setDocsInLocal(docs);
        }
        setUserRequest(false);
      },
      (error) => {
        setLocation();
        apiErrorToast(error);
        setUserRequest(false);
      }
    );
  }, [setLocation, setDocsInLocal, saveUser]);

  // ##########################################
  // UPDATE USER DATA API CALL ON TIMELY BASIS
  // ##########################################

  const getUserAxios = async () => {
    // try {
    //   const resp = await axios.get(BASE_URL + ApiEndpoints.GET_ME_USER, {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    //     },
    //   });
    //   const user = resp.data.data;
    //   const { time } = getRemainingTime(
    //     aepsType === AEPS_TYPE.AEPS1
    //       ? user?.ipay_auth_time
    //       : aepsType === AEPS_TYPE.AEPS2
    //       ? user?.fing_auth_time
    //       : ""
    //   );
    //   // console.log("time subtracted from current time => ", time);
    //   if (time) {
    //     setTimeInSec(3 * 60 - time);
    //   } else {
    //     setTimeInSec(null);
    //     if (checkIf2FaCalled !== "done") setOpenAeps2FAModal(true);
    //   }
    // } catch (error) {}
  };

  useEffect(() => {
    // commented code becux it was being called twice on live
    // refreshUser();
    const interval = setInterval(() => {
      if (isLoggedIn) {
        refreshUser();
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [isLoggedIn, refreshUser]);

  return (
    <CommonContext.Provider
      value={{
        recentData,
        getRecentData,
        recentLoading,
        section,
        setSection,
        openNoti,
        setOpenNoti,
        testRedToast,
        setPushFlag,
        pushFlag,
        refreshUser,
        notiCount,
        setNotiCount,
        userRequest,
        aepsType,
        setAepsType,
        setOpenAeps2FAModal,
        openAeps2FAModal,
        setCheckIf2FaCalled,
        checkIf2FaCalled,
        setTimeInSec,
        timeInSec,
        getUserAxios,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

const useCommonContext = () => {
  return useContext(CommonContext);
};

export default useCommonContext;
