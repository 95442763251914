import { Box, Grid, IconButton, Snackbar, Tooltip } from "@mui/material";
import React, { useContext, useEffect } from "react";
import ApiEndpoints from "../network/ApiEndPoints";
import CachedIcon from "@mui/icons-material/Cached";
import { useState } from "react";
import { CustomStyles } from "../component/CostomStyle";
import { datemonthYear, yyyymmdd } from "../utils/DateUtils";
import GetAdUserInfoByUsername from "../modals/GetAdUserInfoByUsername";
import AuthContext from "../store/AuthContext";
import ApiPaginateSearch from "../component/ApiPaginateSearch";
import { get } from "../network/ApiController";
import moment from "moment";
import { json2Csv, json2Excel } from "../utils/exportToExcel";
import { apiErrorToast } from "../utils/ToastUtil";
import ExcelUploadModal from "../modals/ExcelUploadModal";

// icons
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import LaptopIcon from "@mui/icons-material/Laptop";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import { DateRangePicker } from "rsuite";
import SyncAltIcon from "@mui/icons-material/SyncAlt";

let refresh;
let handleCloseModal;
function refreshFunc(setQueryParams) {
  setQueryParams(`type_txn=PURCHASE`);
  if (refresh) refresh();
}

const MyPurchaseView = () => {
  const { afterToday } = DateRangePicker;
  const [apiData, setApiData] = useState([]);
  const [query, setQuery] = useState();
  const prefilledQuery = "type_txn=PURCHASE";
  const authCtx = useContext(AuthContext);
  const user = authCtx.user;
  const [open, setOpen] = React.useState(false);
  const [request, setRequest] = useState(false);
  const [noOfResponses, setNoOfResponses] = useState(0);
  const [filterValues, setFilterValues] = useState({ date: {}, dateVal: null });

  const [isBig, setIsBig] = React.useState(
    window.innerWidth < 900 ? false : true
  );

  const changeApply = () => {
    if (window.innerWidth < 900) setIsBig(false);
    if (window.innerWidth > 900) setIsBig(true);
  };
  useEffect(() => {
    window.addEventListener("resize", changeApply);
    return () => {
      window.removeEventListener("resize", changeApply);
    };
  }, []);

  const copyToClipBoard = (copyMe) => {
    try {
      navigator.clipboard.writeText(copyMe);
    } catch (err) {}
  };

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getExcel = () => {
    get(
      ApiEndpoints.GET_TRANSACTIONS,
      `${
        query
          ? query + `&page=1&paginate=10&export=1`
          : `page=1&paginate=10&export=1`
      }`,
      setRequest,
      (res) => {
        const apiData = res.data.data;
        const newApiData = apiData.map((item) => {
          const created_at = moment(item.created_at).format("DD-MM-YYYY");
          const time_updated_at = moment(item.updated_at).format("LTS");
          return { ...item, created_at, time_updated_at };
        });
        json2Excel(
          `My Purchase Transactions ${moment(new Date().toJSON()).format(
            "Do MMM YYYY"
          )} | ${moment(new Date().toJSON()).format("hh:mm a")}`,
          JSON.parse(JSON.stringify(newApiData && newApiData))
        );
        handleCloseModal();
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  const getCsv = () => {
    get(
      ApiEndpoints.GET_TRANSACTIONS,
      `${
        query
          ? query + `&page=1&paginate=10&export=1`
          : `page=1&paginate=10&export=1`
      }`,
      setRequest,
      (res) => {
        const apiData = res.data.data;
        const newApiData = apiData.map((item) => {
          const created_at = moment(item.created_at).format("DD-MM-YYYY");
          const time_updated_at = moment(item.updated_at).format("LTS");
          return { ...item, created_at, time_updated_at };
        });
        json2Csv(
          `My Purchase Transactions ${moment(new Date().toJSON()).format(
            "Do MMM YYYY"
          )} | ${moment(new Date().toJSON()).format("hh:mm a")}`,
          JSON.parse(JSON.stringify(newApiData && newApiData))
        );
        handleCloseModal();
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => datemonthYear(row.created_at),
    },
    // {
    //   name: "Platform",
    //   selector: (row) => row.platform,
    // },
    {
      name: "Route",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <div>
            {row.platform === "APP" ? (
              <Tooltip title="APP">
                <InstallMobileIcon fontSize="small" />
              </Tooltip>
            ) : row.platform === "WEB" ? (
              <Tooltip title="WEB">
                <LaptopIcon fontSize="small" />
              </Tooltip>
            ) : row.platform === "ANDROID" ? (
              <Tooltip title="ANDROID">
                <AndroidIcon fontSize="small" />
              </Tooltip>
            ) : row.platform === "IOS" ? (
              <Tooltip title="IOS">
                <AppleIcon fontSize="small" />
              </Tooltip>
            ) : (
              <Tooltip title="API">
                <SyncAltIcon fontSize="small" />
              </Tooltip>
            )}
          </div>
          <div className="fw-bold">{row.platform}</div>
        </div>
      ),
      center: false,

      width: "70px",
    },
    {
      name: "Number",
      selector: (row) => (
        <div style={{ textAlign: "left" }} className="d-flex">
          <span
            style={{ marginRight: "4px" }}
            onClick={() => {
              copyToClipBoard(row.number);
              handleClickSnack();
            }}
          >
            {" "}
            {row.number}
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleCloseSnack}
              message="number copied"
              sx={{ zIndex: 10000 }}
            />
          </span>

          {user && user.username !== Number(row && row.number) ? (
            <GetAdUserInfoByUsername row={row} />
          ) : (
            ""
          )}
        </div>
      ),
      center: false,
    },
    {
      name: "Service",
      selector: (row) => (
        <div style={{ textAlign: "left" }}>{row.operator}</div>
      ),
      center: false,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name: "Net Amount",
      selector: (row) => (
        <div>
          {row.txn_type && row.txn_type === "CR" ? "+" : "-"}
          {row.net_amount}
        </div>
      ),
    },
    {
      name: "Wallet Balance",
      selector: (row) => (
        <div>
          <div>{Number(row.w1).toFixed(2)}</div>
          {/* <div>{Number(row.w2).toFixed(2)}</div> */}
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className="px-2 text-uppercase"
          style={{
            // fontSize: "12px",

            color: "#fff",
            backgroundColor:
              row.status && row.status === "SUCCESS"
                ? "#00BF78"
                : row.status && row.status === "PENDING"
                ? "#F08D17"
                : row.status && row.status === "REFUND"
                ? "#F08D17"
                : "#DC6F6F",
            fontWeight: "bold",
            borderRadius: "4px",
            minWidth: "70px",
          }}
        >
          {row.status && row.status === "SUCCESS"
            ? "Success"
            : row.status && row.status === "PENDING"
            ? "Pending"
            : row.status && row.status === "REFUND"
            ? "Refund"
            : "Failed"}
        </div>
      ),
    },
  ];
  const searchOptions = [{ field: "Number", parameter: "number" }];
  return (
    <Box>
      {/* <Box sx={{ display: "flex", justifyContent: "end" }}>
      
      </Box> */}
      <Grid sx={{ pr: { xs: 1.3, lg: 0 } }}>
        <ApiPaginateSearch
          actionButtons={
            <Grid
              item
              md={8}
              sm={6}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { md: "end", xs: "start" },
                alignItems: "center",
                pr: 5,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "center", mx: 2 }}>
                <DateRangePicker
                  placement={isBig ? "leftStart" : "auto"}
                  showOneCalendar
                  placeholder="Date"
                  size="xs"
                  cleanable
                  value={filterValues.dateVal}
                  onChange={(value) => {
                    const dateVal = value;
                    const dates = {
                      start: dateVal && dateVal[0],
                      end: dateVal && dateVal[1],
                    };
                    setFilterValues({
                      ...filterValues,
                      date: {
                        start: yyyymmdd(dates.start),
                        end: yyyymmdd(dates.end),
                      },
                      dateVal,
                    });
                    if (dateVal) {
                      setQuery(
                        `${prefilledQuery}&start=${yyyymmdd(
                          dateVal[0]
                        )}&end=${yyyymmdd(dateVal[1])}`
                      );
                    } else {
                      setQuery(`${prefilledQuery}`);
                    }
                  }}
                  disabledDate={afterToday()}
                />
              </Box>

              <ExcelUploadModal
                twobuttons="Download Csv"
                btn
                request={request}
                getExcel={getExcel}
                getCsv={getCsv}
                noOfResponses={noOfResponses}
                setQuery={setQuery}
                handleCloseCB={(closeModal) => {
                  handleCloseModal = closeModal;
                }}
              />
              <Tooltip title="refresh">
                <IconButton
                  aria-label="refresh"
                  color="success"
                  onClick={() => {
                    refreshFunc(setQuery);
                  }}
                >
                  <CachedIcon className="refresh-purple" />
                </IconButton>
              </Tooltip>
            </Grid>
          }
          apiEnd={ApiEndpoints.GET_TRANSACTIONS}
          searchOptions={searchOptions}
          setQuery={setQuery}
          columns={columns}
          apiData={apiData}
          setApiData={setApiData}
          tableStyle={CustomStyles}
          queryParam={query ? query : ""}
          returnRefetch={(ref) => {
            refresh = ref;
          }}
          responses={(val) => {
            setNoOfResponses(val);
          }}
          prefilledQuery={prefilledQuery}
        />
        {/* <ApiPaginate
          apiEnd={ApiEndpoints.GET_TRANSACTIONS}
          columns={columns}
          apiData={apiData}
          tableStyle={CustomStyles}
          setApiData={setApiData}
          ExpandedComponent=""
          queryParam={query ? query : ""}
          returnRefetch={(ref) => {
            refresh = ref;
          }}
        /> */}
      </Grid>
    </Box>
  );
};

export default MyPurchaseView;
