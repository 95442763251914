import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import ApiEndpoints from "../network/ApiEndPoints";
import CachedIcon from "@mui/icons-material/Cached";
import { useState } from "react";
import { CustomStyles } from "../component/CostomStyle";
import { datemonthYear } from "../utils/DateUtils";
import ApiPaginateSearch from "../component/ApiPaginateSearch";

let refresh;
function refreshFunc(setQueryParams) {
  setQueryParams(`type_txn=LEDGER`);
  if (refresh) refresh();
}
const MyLedgerView = () => {
  const [apiData, setApiData] = useState([]);
  const [query, setQuery] = useState();
  const prefilledQuery = "type_txn=LEDGER";
  const columns = [
    {
      name: "Date",
      selector: (row) => datemonthYear(row.created_at),
    },
    {
      name: "Number",
      selector: (row) => (
        <>
          {row.number}
          {/* <GetAdUserInfoByUsername row={row} /> */}
        </>
      ),
    },
    {
      name: "Platform",
      selector: (row) => row.platform,
      width: "80px",
    },
    {
      name: "EST",
      selector: (row) => row.establishment,
    },
    {
      name: "Service",
      selector: (row) => row.operator,
    },
    {
      name: "Amount",
      selector: (row) => (
        <>
          <div>{Number(row.amount).toFixed(2)}</div>
          <div>
            {row.txn_type && row.txn_type === "CR" ? "+" : "-"}
            {Number(row.net_amount).toFixed(2)}
          </div>
        </>
      ),
    },
    {
      name: "Comm/TDS",
      selector: (row) => (
        <>
          <div>+{Number(row.ad_comm).toFixed(2)}</div>
          <div style={{ color: "red " }}>-{Number(row.ad_tds).toFixed(2)}</div>
        </>
      ),
    },

    {
      name: "Closing",
      selector: (row) =>
        row.type === "W2W TRANSFER"
          ? Number(row.w1).toFixed(2)
          : Number(row.ad_closing).toFixed(2),
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
  ];

  const searchOptions = [
    { field: "Number", parameter: "number" },
    { field: "EST", parameter: "establishment" },
  ];

  return (
    <Box>
      {/* <Box sx={{ display: "flex", justifyContent: "end" }}>
       
      </Box> */}
      <div>
        <ApiPaginateSearch
          actionButtons={
            <Tooltip title="refresh">
              <IconButton
                aria-label="refresh"
                color="success"
                onClick={() => {
                  refreshFunc(setQuery);
                }}
              >
                <CachedIcon className="refresh-purple" />
              </IconButton>
            </Tooltip>
          }
          apiEnd={ApiEndpoints.GET_TRANSACTIONS}
          prefilledQuery={prefilledQuery}
          searchOptions={searchOptions}
          setQuery={setQuery}
          columns={columns}
          apiData={apiData}
          setApiData={setApiData}
          tableStyle={CustomStyles}
          ExpandedComponent={false}
          queryParam={query ? query : ""}
          returnRefetch={(ref) => {
            refresh = ref;
          }}
        />
        {/* <ApiPaginate
          apiEnd={ApiEndpoints.GET_TRANSACTIONS}
          columns={columns}
          apiData={apiData}
          tableStyle={CustomStyles}
          setApiData={setApiData}
          ExpandedComponent=""
          queryParam={query ? query : ""}
          returnRefetch={(ref) => {
            refresh = ref;
          }}
        /> */}
      </div>
    </Box>
  );
};

export default MyLedgerView;
