import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import ModalFooter from "./ModalFooter";
import ModalHeader from "./ModalHeader";
import { useState } from "react";
import { getActiveColor } from "../theme/setThemeColor";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,

  height: "max-content",
  overflowY: "scroll",
  p: 2,
};

const CheckResponseModal = ({ row }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "grid",
        justifyContent: "center",
      }}
    >
      <Button
        variant="text"
        sx={{
          width: "12px",
          height: "15px",
          fontSize: "8px",
          color: getActiveColor(),
          // backgroundColor: "#9586EC",
          "&:hover": {
            color: "#fff",
            backgroundColor: getActiveColor(),
          },
          borderRadius: "2px",

          mt: 0.5,
        }}
        onClick={handleOpen}
      >
        response
      </Button>

      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="sm_modal">
            <ModalHeader title="API Response" handleClose={handleClose} />
            <Box
              component="form"
              id="response"
              noValidate
              autoComplete="off"
              className="text-center"
              onSubmit={(event) => {
                handleClose();
              }}
              sx={{
                "& .MuiTextField-root": { m: 2 },
                objectFit: "contain",
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  whiteSpace: "break-spaces",
                  overflow: "scroll",
                  textOverflow: "clip",
                }}
              >
                {row && row.api_response === ""
                  ? "No Response yet"
                  : row.api_response}
              </div>
            </Box>
            <ModalFooter form="response" btn="YES" />
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};
export default CheckResponseModal;
