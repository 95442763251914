/* eslint-disable no-unused-vars */
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import ChatBox from "../commons/ChatBox";
import DefaultNavbar from "../component/landingpage/DefaultNavbar";
import routes from "../component/landingpage/DefaultNavbar/routes";
import { Button } from "@mui/material";
import Navbar from "../component/Navbar";

const MainPage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Navbar />
      <Outlet />
      {/* <ChatBox /> */}
      <Footer />
    </div>
  );
};

export default MainPage;
